import React, { Component } from "react";
import { Switch } from "react-router-dom";
import { connect } from "react-redux";
import { authCheck, logout } from "./store/SignIn/SignIn.Actions";

import "./App.css";

import SignInLayout from "./hoc/Layouts/SignInLayout/SignInLayout";
import MainLayout from "./hoc/Layouts/MainLayout/MainLayout";

import SignIn from "./containers/SignIn/SignIn";
import Dashboard from "./containers/Dashboard/Dashboard";
import UsersIndex from "./containers/Users/Index/Index";
import UsersView from "./containers/Users/View/View";
import UsersCreate from "./containers/Users/Create/Create";
import UsersUpdate from "./containers/Users/Update/Update";
import AdminsIndex from "./containers/Admins/Index/Index";
import AdminsCreate from "./containers/Admins/Create/Create";
import AdminsUpdate from "./containers/Admins/Update/Update";
import ChangePassword from "./containers/ChangePassword/ChangePassword";
import CategoriesIndex from "./containers/Categories/Index/Index";
import CategoriesView from "./containers/Categories/View/View";
import CategoriesCreate from "./containers/Categories/Create/Create";
import CategoriesUpdate from "./containers/Categories/Update/Update";
import ApplicationsIndex from "./containers/Applications/Index/Index";
import ApplicationsCreate from "./containers/Applications/Create/Create";
import ApplicationsUpdate from "./containers/Applications/Update/Update";
import RegionsIndex from "./containers/Regions/Index/Index";
import RegionsCreate from "./containers/Regions/Create/Create";
import RegionsUpdate from "./containers/Regions/Update/Update";
import WarehousesIndex from "./containers/Warehouses/Index/Index";
import WarehousesCreate from "./containers/Warehouses/Create/Create";
import WarehousesUpdate from "./containers/Warehouses/Update/Update";
import SlidesIndex from "./containers/Slides/Index/Index";
import SlidesCreate from "./containers/Slides/Create/Create";
import SlidesUpdate from "./containers/Slides/Update/Update";
import ProductsIndex from "./containers/Products/Index/Index";
import ProductsCreate from "./containers/Products/Create/Create";
import ProductsUpdate from "./containers/Products/Update/Update";
import ProductsView from "./containers/Products/View/View";
import OrdersIndex from "./containers/Orders/Index/Index";
import OrdersView from "./containers/Orders/View/View";
import ProductEnquiresIndex from "./containers/ProductEnquires/Index/Index";
import ProductEnquiresView from "./containers/ProductEnquires/View/View";
import CertificatesIndex from "./containers/Certificates/Index/Index";
import CertificatesCreate from "./containers/Certificates/Create/Create";
import CertificatesUpdate from "./containers/Certificates/Update/Update";

class App extends Component {
  componentDidMount() {
    this.props.onAutoSignIn();
  }

  render() {
    let routes = (
      <Switch>
        <SignInLayout path="/" component={SignIn} />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <MainLayout path="/" exact component={Dashboard} />
          <MainLayout path="/users" exact component={UsersIndex} />
          <MainLayout path="/users/:id/view" exact component={UsersView} />
          <MainLayout path="/users/create" exact component={UsersCreate} />
          <MainLayout path="/users/:id/edit" exact component={UsersUpdate} />
          <MainLayout path="/admins" exact component={AdminsIndex} />
          <MainLayout path="/admins/create" exact component={AdminsCreate} />
          <MainLayout path="/admins/:id/edit" exact component={AdminsUpdate} />
          <MainLayout path="/categories" exact component={CategoriesIndex} />
          <MainLayout
            path="/categories/:id/view"
            exact
            component={CategoriesView}
          />
          <MainLayout
            path="/categories/create"
            exact
            component={CategoriesCreate}
          />
          <MainLayout
            path="/categories/:id/edit"
            exact
            component={CategoriesUpdate}
          />
          <MainLayout
            path="/applications"
            exact
            component={ApplicationsIndex}
          />
          <MainLayout
            path="/applications/create"
            exact
            component={ApplicationsCreate}
          />
          <MainLayout
            path="/applications/:id/edit"
            exact
            component={ApplicationsUpdate}
          />
          <MainLayout path="/regions" exact component={RegionsIndex} />
          <MainLayout path="/regions/create" exact component={RegionsCreate} />
          <MainLayout
            path="/regions/:id/edit"
            exact
            component={RegionsUpdate}
          />
          <MainLayout path="/warehouses" exact component={WarehousesIndex} />
          <MainLayout
            path="/warehouses/create"
            exact
            component={WarehousesCreate}
          />
          <MainLayout
            path="/warehouses/:id/edit"
            exact
            component={WarehousesUpdate}
          />
          <MainLayout path="/slides" exact component={SlidesIndex} />
          <MainLayout path="/slides/create" exact component={SlidesCreate} />
          <MainLayout path="/slides/:id/edit" exact component={SlidesUpdate} />
          <MainLayout path="/products" exact component={ProductsIndex} />
          <MainLayout
            path="/products/create"
            exact
            component={ProductsCreate}
          />
          <MainLayout
            path="/products/:id/edit"
            exact
            component={ProductsUpdate}
          />
          <MainLayout
            path="/products/:id/view"
            exact
            component={ProductsView}
          />
          <MainLayout path="/orders" exact component={OrdersIndex} />
          <MainLayout path="/orders/:id/view" exact component={OrdersView} />
          <MainLayout
            path="/product-enquires"
            exact
            component={ProductEnquiresIndex}
          />
          <MainLayout
            path="/product-enquires/:id/view"
            exact
            component={ProductEnquiresView}
          />
          <MainLayout
            path="/certificates"
            exact
            component={CertificatesIndex}
          />
          <MainLayout
            path="/certificates/create"
            exact
            component={CertificatesCreate}
          />
          <MainLayout
            path="/certificates/:id/edit"
            exact
            component={CertificatesUpdate}
          />
          <MainLayout path="/change-password" component={ChangePassword} />
          <MainLayout path="/logout" component={logout} />
        </Switch>
      );
    }

    return routes;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.SignIn.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAutoSignIn: () => dispatch(authCheck()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
