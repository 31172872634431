import React from "react";

import styles from "./EditButton.module.css";

import EditIcon from "../../assets/images/edit-icon.svg";
import EditIconDark from "../../assets/images/edit-icon-dark.svg";

const EditButton = (props) => {
  return (
    <div className={styles.EditButton} onClick={props.clicked}>
      <div className={styles.Button}>
        <img
          src={props.theme && props.theme === "Dark" ? EditIconDark : EditIcon}
          alt="Edit"
        />
      </div>
    </div>
  );
};

export default EditButton;
