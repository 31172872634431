import React, { useState, useEffect, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Index.module.css";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Button from "../../../components/Button/Button";
import Spinner from "../../../components/Spinner/Spinner";
import EditButton from "../../../components/EditButton/EditButton";
import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import DialogBox from "../../../components/DialogBox/DialogBox";

import AddNewIcon from "../../../assets/images/add-new-icon.svg";

const Index = (props) => {
  const [showDeleteDialogBox, setShowDeleteDialogBox] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    axios
      .get("/slides?per-page=999&page=1")
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const handleDelete = useCallback(
    (id = null) => {
      setShowDeleteDialogBox(!showDeleteDialogBox);
      setActiveItem(id);
    },
    [setShowDeleteDialogBox, showDeleteDialogBox, setActiveItem]
  );

  const confirmDelete = () => {
    axios
      .delete(`/slides/${activeItem}`)
      .then((response) => {
        toast.success(response.data.message);
        setShowDeleteDialogBox(false);
        fetchData();
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = [...data];
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setData(newItems);
    const newOrder = [];
    newItems.forEach((item) => {
      newOrder.push(item.id);
    });

    const formValues = {
      new_order: newOrder,
    };

    axios
      .put("/slides/update-order", formValues)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Slides | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Slides</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/slides">Slides</Link>
            </li>
          </Breadcrumb>
        </div>
        <div className="ContentRight">
          <div className="Action">
            <Button btnType="Success" elementType="link" link="/slides/create">
              <img src={AddNewIcon} alt="New" /> <span>New</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="PageContent">
        <div className={styles.Slides}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable-1">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {data.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={`${item.id}-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className={styles.Slide}>
                            <div className={styles.Image}>
                              <img src={item.image} />
                            </div>
                            <div className={styles.Actions}>
                              <EditButton
                                clicked={() =>
                                  props.history.push(`/slides/${item.id}/edit`)
                                }
                              />
                              <DeleteButton
                                clicked={() => handleDelete(item.id)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <DialogBox
        show={showDeleteDialogBox}
        confirm={confirmDelete}
        cancel={handleDelete}
        title="Delete Slide"
        message="Are you sure you want to delete this Slide?"
      />
      <Spinner isLoading={isLoading} />
      <ToastContainer />
    </React.Fragment>
  );
};

export default Index;
