import React, { useCallback, useState, useEffect } from "react";
import axios from "../../../axios";
import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Documents.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import EditButton from "../../../components/EditButton/EditButton";
import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Modal from "../../../components/Modal/Modal";
import DialogBox from "../../../components/DialogBox/DialogBox";

import AddNewIcon from "../../../assets/images/add-new-icon.svg";

const Documents = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(() => {
    axios
      .get(`/products/${props.productId}/documents`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [props]);

  const [showDeleteDialogBox, setShowDeleteDialogBox] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);

  const handleNewForm = () => {
    const formValues = {
      id: "",
      name: "",
      document: "",
    };

    setFormValues(formValues);
    setEditMode(false);
    setShowNewForm(!showNewForm);
  };

  const handleEdit = (id) => {
    axios
      .get(`/products/${props.productId}/documents/${id}`)
      .then((response) => {
        const formValues = {
          id: response.data.id,
          name: response.data.name,
          document: response.data.document,
        };

        setFormValues(formValues);
        setEditMode(true);
        setShowNewForm(true);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const initialValues = {
    type: formValues ? formValues.type : "",
    name: formValues ? formValues.name : "",
    document: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    document: Yup.string().required("Document is required"),
  });

  const validationSchemaEdit = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);

    if (!editMode) {
      if (values.document) {
        formData.append("document", values.document);
      }
      axios
        .post(`/products/${props.productId}/documents`, formData)
        .then((response) => {
          toast.success(response.data.message);
          fetchData();
          setShowNewForm(false);
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      formData.append("document", values.document);
      axios
        .put(
          `/products/${props.productId}/documents/${formValues.id}`,
          formData
        )
        .then((response) => {
          toast.success(response.data.message);
          fetchData();
          setShowNewForm(false);
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const newForm = showNewForm ? (
    <Modal
      show={showNewForm}
      modalClosed={handleNewForm}
      title={`${editMode ? "EDIT" : "ADD"}
        Document`}
    >
      <div className="FormContainer ModalForm">
        <Formik
          initialValues={initialValues}
          validationSchema={editMode ? validationSchemaEdit : validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form>
                <div className={["FormBody", styles.FormBody].join(" ")}>
                  <FormikControl
                    control="input"
                    type="text"
                    label="Name *"
                    name="name"
                    maxLength="255"
                  />

                  <FormikControl
                    control="fileInput"
                    label="Document *"
                    name="document"
                    accept=".pdf"
                    setFieldValue={setFieldValue}
                    fileFormats="PDF"
                    maxFileSize="10MB"
                    value={formValues ? formValues.document : ""}
                  />
                </div>
                <div className="FormActions">
                  <Button
                    btnType="Primary"
                    type="Submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  ) : null;

  const handleDelete = (id = null) => {
    setShowDeleteDialogBox(!showDeleteDialogBox);
    setActiveItem(id);
  };

  const confirmDelete = () => {
    axios
      .delete(`/products/${props.productId}/documents/${activeItem}`)
      .then((response) => {
        toast.success(response.data.message);
        setShowDeleteDialogBox(false);
        fetchData();
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.ProductDocuments}>
      <div className={styles.PageActions}>
        <Button btnType="Success" clicked={handleNewForm}>
          <img src={AddNewIcon} alt="New" /> <span>Add Document</span>
        </Button>
      </div>
      <div className={styles.DocumentsWrapper}>
        {data.length ? (
          <>
            <div className={styles.Documents}>
              <table className="type-1">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Uploaded at</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => {
                    return (
                      <tr>
                        <td>
                          <a href={item.document} target="_blank">
                            {item.name}
                          </a>
                        </td>
                        <td>
                          {moment(item.created_at).format(
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </td>
                        <td className="ActionsColumn">
                          <EditButton clicked={() => handleEdit(item.id)} />
                          <DeleteButton clicked={() => handleDelete(item.id)} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {props.mode !== "edit" ? (
              <div className={["FormActions", styles.FormActions].join(" ")}>
                <Button
                  btnType="Primary"
                  clicked={() => props.handleActiveStep(5)}
                >
                  Next
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <div>No Documents found</div>
        )}
      </div>
      <DialogBox
        show={showDeleteDialogBox}
        confirm={confirmDelete}
        cancel={handleDelete}
        title="Delete Document"
        message="Are you sure you want to delete this Document?"
      />
      {newForm}
      <ToastContainer />
    </div>
  );
};

export default Documents;
