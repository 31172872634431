import React from "react";
import { Field, ErrorMessage } from "formik";

import ErrorMsg from "../../ErrorMsg/ErrorMsg";

import styles from "./Switch.module.css";

const Switch = (props) => {
  let { name, ...rest } = props;

  const label = props.label ? (
    <label htmlFor={name} className="ControlLabel">
      {props.label}
    </label>
  ) : null;

  return (
    <div className={["FormGroup", styles.SwitchInput].join(" ")}>
      {label}
      <label className={styles.Switch}>
        <Field
          type="checkbox"
          id={name}
          name={name}
          {...rest}
        />
        <span className={[styles.Slider, styles.Round].join(" ")}></span>
      </label>
      <ErrorMessage name={name} component={ErrorMsg} />
    </div>
  );
};

export default Switch;
