import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Update.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";

const Update = () => {
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();

  const fetchData = useCallback(() => {
    axios
      .get(`/users/${params.id}`)
      .then((response) => {
        const data = response.data;
        data.status = data.status.id.toString();
        data.verified_email = data.verified_email.id.toString();
        data.verified_account = data.verified_account.id.toString();
        setData({ ...data });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialValues = {
    ...data,
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required("First Name is required")
      .trim()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field"),
    last_name: Yup.string()
      .required("Last Name is required")
      .trim()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field"),
    phone: Yup.string().required("Phone is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(/^[a-zA-Z0-9.@_ ]+$/, "Invalid Email ID")
      .test("regex", "Invalid Email ID", (val) => {
        const regExpEmail = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);
        return regExpEmail.test(val);
      }),
    company_name: Yup.string().required("Company Name is required"),
    website: Yup.string().required("Website is required"),
    street_address_1: Yup.string().required("Street Address 1 is required"),
    city: Yup.string().required("City is required"),
    state_province: Yup.string().required("State/Province is required"),
    zip_postal_code: Yup.string().required("Zip/Postal Code is required"),
    country: Yup.string().required("Country is required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    axios
      .put(`/users/${data.id}`, values)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const statusOptions = [
    { value: "0", label: "Disabled" },
    { value: "1", label: "Enabled" },
  ];

  const verifiedEmailOptions = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  const verifiedAccountOptions = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  return (
    <div className={styles.Create}>
      <Helmet>
        <title>Edit | Users | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Users</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
            <li>Edit</li>
          </Breadcrumb>
        </div>
      </div>
      <div className="PageContent DataForm">
        <div className="ContentHeader">
          <div className="Title">
            <h1>Edit User</h1>
          </div>
        </div>
        <div className="MainContent">
          <div className="FormContainer">
            {data ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setFieldValue }) => {
                  return (
                    <Form>
                      <div className={["FormBody", styles.FormBody].join(" ")}>
                        <FormikControl
                          control="input"
                          type="text"
                          label="First Name *"
                          name="first_name"
                          maxLength="30"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Last Name *"
                          name="last_name"
                          maxLength="30"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Phone *"
                          name="phone"
                          maxLength="10"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Email *"
                          name="email"
                          maxLength="150"
                          autoComplete="new-password"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Company Name *"
                          name="company_name"
                          maxLength="100"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Website *"
                          name="website"
                          maxLength="150"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Street Address 1 *"
                          name="street_address_1"
                          maxLength="255"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Street Address 2"
                          name="street_address_2"
                          maxLength="255"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="City *"
                          name="city"
                          maxLength="255"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Street Address 2"
                          name="street_address_2"
                          maxLength="50"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="State/Province *"
                          name="state_province"
                          maxLength="50"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Zip/Postal Code *"
                          name="zip_postal_code"
                          maxLength="10"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Country *"
                          name="country"
                          maxLength="50"
                        />

                        <FormikControl
                          control="select"
                          label="Status *"
                          name="status"
                          value={data.status}
                          options={statusOptions}
                          setFieldValue={setFieldValue}
                        />

                        <FormikControl
                          control="select"
                          label="Verified Email *"
                          name="verified_email"
                          value={data.verified_email}
                          options={verifiedEmailOptions}
                          setFieldValue={setFieldValue}
                        />
                      </div>

                      <div
                        className={["FormActions", styles.FormActions].join(
                          " "
                        )}
                      >
                        <Button
                          btnType="Primary"
                          type="Submit"
                          width="W100"
                          isLoading={isLoading}
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : null}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Update;
