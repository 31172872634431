import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosCheckmarkCircle } from "react-icons/io";

import styles from "./View.module.css";

import EditButton from "../../../components/EditButton/EditButton";
import DialogBox from "../../../components/DialogBox/DialogBox";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Button from "../../../components/Button/Button";
import AddNewIcon from "../../../assets/images/add-new-icon.svg";

const View = (props) => {
  const [data, setData] = useState(null);
  const [showApproveAccount, setShowApproveAccount] = useState(false);

  const params = useParams();

  const fetchData = useCallback(() => {
    axios
      .get(`/users/${params.id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleApproveAccount = () => {
    setShowApproveAccount(!showApproveAccount);
  };

  const handleAppoveAccountConfirm = () => {
    axios
      .put(`/users/${params.id}/approve-account`)
      .then((response) => {
        toast.success(response.data.message);
        fetchData();
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setShowApproveAccount(false);
      });
  };

  return (
    <div className={styles.View}>
      <Helmet>
        <title>View Details | User | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Users</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
            <li>View</li>
          </Breadcrumb>
        </div>
        <div className="ContentRight">
          <Button btnType="Success" elementType="link" link="/users/create">
            <img src={AddNewIcon} alt="New User" /> <span>New</span>
          </Button>
        </div>
      </div>
      {data ? (
        <div className={["PageContent", styles.UserOverview].join(" ")}>
          <div className={styles.DetailsContainer}>
            <div className="ContentHeader">
              <h2>User Details</h2>
              <div className="HeaderActions">
                {data && data.verified_account.id === 0 ? (
                  <Button
                    btnType="Success"
                    clicked={() => handleApproveAccount()}
                  >
                    <IoIosCheckmarkCircle /> <span>Approve Account</span>
                  </Button>
                ) : null}
                <EditButton
                  clicked={() => props.history.push(`/users/${data.id}/edit`)}
                />
              </div>
            </div>
            <div className={styles.DetailOverview}>
              <div className={styles.Detail}>
                <div>Name</div>
                <div>{data.first_name + " " + data.last_name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Email</div>
                <div>{data.email}</div>
              </div>
              <div className={styles.Detail}>
                <div>Phone</div>
                <div>{data.phone}</div>
              </div>
              <div className={styles.Detail}>
                <div>Company Name</div>
                <div>{data.company_name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Website</div>
                <div>{data.website}</div>
              </div>
              <div className={styles.Detail}>
                <div>Street Address 1</div>
                <div>{data.street_address_1}</div>
              </div>
              <div className={styles.Detail}>
                <div>Street Address 2</div>
                <div>{data.street_address_2}</div>
              </div>
              <div className={styles.Detail}>
                <div>City</div>
                <div>{data.city}</div>
              </div>
              <div className={styles.Detail}>
                <div>State/Province</div>
                <div>{data.state_province}</div>
              </div>
              <div className={styles.Detail}>
                <div>Zip/Postal Code</div>
                <div>{data.zip_postal_code}</div>
              </div>
              <div className={styles.Detail}>
                <div>Country</div>
                <div>{data.country}</div>
              </div>
              <div className={styles.Detail}>
                <div>Verified Email</div>
                <div>{data.verified_email.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Verified Account</div>
                <div>{data.verified_account.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Status</div>
                <div>{data.status.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Registered On</div>
                <div>
                  {moment(data.created_at).format("DD MMM YYYY, h:mm A")}
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Last Updated On</div>
                <div>
                  {data.updated_at
                    ? moment(data.updated_at).format("DD MMM YYYY, h:mm A")
                    : "NA"}
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Last Login</div>
                <div>
                  {data.last_login
                    ? moment(data.last_login).format("DD MMM YYYY, h:mm A")
                    : "NA"}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <DialogBox
        show={showApproveAccount}
        confirm={handleAppoveAccountConfirm}
        cancel={handleApproveAccount}
        title="Approve Account"
        message="Are you sure you want to approve this account?"
      />
      <ToastContainer />
    </div>
  );
};

export default View;
