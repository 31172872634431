import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { Link } from "react-router-dom";
import axios from "../../../axios";
import moment from "moment";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Button from "../../../components/Button/Button";
import Spinner from "../../../components/Spinner/Spinner";
import SearchBox from "../../../components/SearchBox/SearchBox";
import EditButton from "../../../components/EditButton/EditButton";
import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import DialogBox from "../../../components/DialogBox/DialogBox";

import AddNewIcon from "../../../assets/images/add-new-icon.svg";
import PrevIcon from "../../../assets/images/left-arrow-icon.svg";
import FirstIcon from "../../../assets/images/double-left-arrow-icon.svg";
import NextIcon from "../../../assets/images/right-arrow-icon.svg";
import LastIcon from "../../../assets/images/double-right-arrow-icon.svg";

const Table = ({
  columns,
  data,
  onSort,
  fetchData,
  pageCount: controlledPageCount,
  recordCount,
  setSearchKeyword,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: "name",
            desc: false,
          },
        ],
      },
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    onSort({ sortBy, pageIndex, pageSize });
    fetchData({ pageIndex, pageSize, sortBy });
  }, [fetchData, pageIndex, pageSize, onSort, sortBy]);

  const searchTable = (event) => {
    if (event.key === "Enter") {
      setSearchKeyword(event.target.value);
    }
  };

  return (
    <>
      <div className="TableHeader">
        <div className="SearchTable">
          <SearchBox
            placeholder="Search by Name"
            onKeyPress={(event) => searchTable(event)}
          />
        </div>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {recordCount > 0 ? (
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="10000">No records found!</td>
            </tr>
          </tbody>
        )}
      </table>
      <div className="TableFooter">
        <div className="TableRecordsCount">
          <div className="PageSize">
            <label>Show</label>
            <select
              className="FormControl"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50, 100, 500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="RecordsStartEnd">
            Showing {page.length} of {recordCount}
          </div>
        </div>
        <div className="TablePagination">
          <div className="PageCountStat">
            Page
            <span>
              {pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div>|</div>
          <div className="GoToPage">
            <label>Go to page</label>
            <input
              className="FormControl"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </div>
          <div className="PaginationNav">
            <Button
              type="button"
              btnType="Default"
              clicked={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <img src={FirstIcon} alt="First Page" />
            </Button>
            <Button
              type="button"
              btnType="Default"
              clicked={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <img src={PrevIcon} alt="Previous Page" />
            </Button>
            <Button
              type="button"
              btnType="Default"
              clicked={() => nextPage()}
              disabled={!canNextPage}
            >
              <img src={NextIcon} alt="Next Page" />
            </Button>
            <Button
              type="button"
              btnType="Default"
              clicked={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <img src={LastIcon} alt="Last Page" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const Index = (props) => {
  const [showDeleteDialogBox, setShowDeleteDialogBox] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [recordCount, setRecordCount] = useState(0);
  const [startRecord, setStartRecord] = useState(0);
  const [endRecord, setEndRecord] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageDetails, setPageDetails] = useState(null);
  const [tableId, setTableId] = useState(1);
  const fetchIdRef = useRef(0);

  const handleDelete = useCallback(
    (id = null) => {
      setShowDeleteDialogBox(!showDeleteDialogBox);
      setActiveItem(id);
    },
    [setShowDeleteDialogBox, showDeleteDialogBox, setActiveItem]
  );

  const confirmDelete = () => {
    axios
      .delete(`/warehouses/${activeItem}`)
      .then((response) => {
        toast.success(response.data.message);
        setShowDeleteDialogBox(false);
        loadData(
          pageDetails.pageSize,
          pageDetails.pageIndex,
          pageDetails.sortBy
        );
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Region",
        id: "region",
        accessor: (d) => d.region.name,
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Created At",
        id: "created_at",
        accessor: (d) => moment(d.created_at).format("DD MMM YYYY, hh:mm A"),
      },
      {
        Header: "Updated At",
        id: "updated_at",
        accessor: (d) =>
          d.updated_at
            ? moment(d.updated_at).format("DD MMM YYYY, hh:mm A")
            : null,
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell }) => [
          <EditButton
            clicked={() =>
              props.history.push(`/warehouses/${cell.row.values.id}/edit`)
            }
          />,
          <DeleteButton clicked={() => handleDelete(cell.row.values.id)} />,
        ],
        disableSortBy: true,
      },
    ],
    [props.history, handleDelete]
  );

  const loadData = useCallback(
    (pageSize, pageIndex, sortBy) => {
      let orderBy = {
        order: "name",
        by: "ASC",
      };

      if (sortBy.length) {
        orderBy = {
          order: sortBy[0].id,
          by: sortBy[0].desc ? "DESC" : "ASC",
        };
      }

      axios
        .get(
          "/warehouses?per-page=" +
            pageSize +
            "&page=" +
            (pageIndex + 1) +
            "&keyword=" +
            searchKeyword +
            "&order=" +
            orderBy.order +
            "&by=" +
            orderBy.by
        )
        .then((response) => {
          setData(response.data);
          setPageCount(response.headers["x-pagination-page-count"]);
          setRecordCount(response.headers["x-pagination-total-count"]);
          setStartRecord(response.headers["x-pagination-start-record"]);
          setEndRecord(response.headers["x-pagination-end-Record"]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    },
    [searchKeyword]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        loadData(pageSize, pageIndex, sortBy);
      }

      setPageDetails({
        pageIndex: pageIndex,
        pageSize: pageSize,
        sortBy: sortBy,
      });
    },
    [loadData]
  );

  const handleSort = useCallback(() => {
    setLoading(true);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Warehouses | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Warehouses</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/warehouses">Warehouses</Link>
            </li>
          </Breadcrumb>
        </div>
        <div className="ContentRight">
          <div className="Action">
            <Button
              btnType="Success"
              elementType="link"
              link="/warehouses/create"
            >
              <img src={AddNewIcon} alt="New" /> <span>New</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="PageContent DataTable">
        <Table
          key={tableId}
          columns={columns}
          data={data}
          onSort={handleSort}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          recordCount={recordCount}
          startRecord={startRecord}
          endRecord={endRecord}
          setSearchKeyword={setSearchKeyword}
        />
      </div>
      <DialogBox
        show={showDeleteDialogBox}
        confirm={confirmDelete}
        cancel={handleDelete}
        title="Delete Warehouse"
        message="Are you sure you want to delete this Warehouse?"
      />
      <Spinner isLoading={loading} />
      <ToastContainer />
    </React.Fragment>
  );
};

export default Index;
