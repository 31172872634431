import React, { useCallback, useState, useEffect } from "react";
import axios from "../../../axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Prices.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import EditButton from "../../../components/EditButton/EditButton";
import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Modal from "../../../components/Modal/Modal";
import DialogBox from "../../../components/DialogBox/DialogBox";

import AddNewIcon from "../../../assets/images/add-new-icon.svg";

const Prices = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  // load Regions
  const loadRegions = (inputValue) => {
    return axios
      .get(`/regions?page=1&per-page=20&query=${inputValue}`)
      .then((res) => res.data);
  };

  // load Sizes
  const loadSizes = (inputValue) => {
    const sizes = [];
    return axios
      .get(
        `/products/${props.productId}/sizes?page=1&per-page=20&query=${inputValue}`
      )
      .then((res) => {
        res.data.map((item) => {
          sizes.push({
            id: item.id,
            name: item.weight + "kg " + item.package_type.name,
          });
        });
        return sizes;
      });
  };

  const fetchData = useCallback(() => {
    axios
      .get(`/products/${props.productId}/prices`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [props]);

  const [showDeleteDialogBox, setShowDeleteDialogBox] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);

  const handleNewForm = () => {
    const formValues = {
      id: "",
      region_id: "",
      size_id: "",
      quantity: "",
      price: "",
    };

    setFormValues(formValues);
    setEditMode(false);
    setShowNewForm(!showNewForm);
  };

  const handleEdit = (item) => {
    const formValues = {
      ...item,
      region_id: item.region.id.toString(),
      size_id: item.size.id.toString(),
    };
    setFormValues(formValues);
    setEditMode(true);
    setShowNewForm(true);
  };

  const initialValues = {
    region_id: formValues ? formValues.region_id : "",
    size_id: formValues ? formValues.size_id : "",
    quantity: formValues ? formValues.quantity : "",
    price: formValues ? formValues.price : "",
  };

  const validationSchema = Yup.object({
    region_id: Yup.string().required("Region is required"),
    size_id: Yup.string().required("Size is required"),
    quantity: Yup.string().required("Quantity is required"),
    price: Yup.string().required("Price is required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    if (!editMode) {
      axios
        .post(`/products/${props.productId}/prices`, values)
        .then((response) => {
          toast.success(response.data.message);
          fetchData();
          setShowNewForm(false);
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      axios
        .put(`/products/${props.productId}/prices/${formValues.id}`, values)
        .then((response) => {
          toast.success(response.data.message);
          fetchData();
          setShowNewForm(false);
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const newForm = showNewForm ? (
    <Modal
      show={showNewForm}
      modalClosed={handleNewForm}
      title={`${editMode ? "EDIT" : "ADD"}
        Price`}
    >
      <div className="FormContainer ModalForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form>
                <div className={["FormBody", styles.FormBody].join(" ")}>
                  <FormikControl
                    control="selectAsync"
                    label="Region *"
                    name="region_id"
                    value={formValues.region}
                    setFieldValue={setFieldValue}
                    loadOptions={loadRegions}
                  />

                  <FormikControl
                    control="selectAsync"
                    label="Size *"
                    name="size_id"
                    value={formValues.size}
                    setFieldValue={setFieldValue}
                    loadOptions={loadSizes}
                  />

                  <FormikControl
                    control="input"
                    type="text"
                    label="Quantity *"
                    name="quantity"
                  />

                  <FormikControl
                    control="input"
                    type="text"
                    label="Price *"
                    name="price"
                  />
                </div>
                <div className="FormActions">
                  <Button
                    btnType="Primary"
                    type="Submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  ) : null;

  const handleDelete = (id = null) => {
    setShowDeleteDialogBox(!showDeleteDialogBox);
    setActiveItem(id);
  };

  const confirmDelete = () => {
    axios
      .delete(`/products/${props.productId}/prices/${activeItem}`)
      .then((response) => {
        toast.success(response.data.message);
        setShowDeleteDialogBox(false);
        fetchData();
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.ProductPrices}>
      <div className={styles.PageActions}>
        <Button btnType="Success" clicked={handleNewForm}>
          <img src={AddNewIcon} alt="New" /> <span>Add Price</span>
        </Button>
      </div>
      <div className={styles.PricesWrapper}>
        {data.length ? (
          <>
            <div className={styles.Prices}>
              <table className="type-1">
                <thead>
                  <tr>
                    <th>Region</th>
                    <th>Size</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => {
                    return (
                      <tr>
                        <td className={styles.Region}>
                          <img src={item.region.icon} width="25px" />{" "}
                          {item.region.name}
                        </td>
                        <td>{item.size.name}</td>
                        <td>{item.quantity}</td>
                        <td>
                          {item.region.currency} {item.price}
                        </td>
                        <td className="ActionsColumn">
                          <EditButton clicked={() => handleEdit(item)} />
                          <DeleteButton clicked={() => handleDelete(item.id)} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {props.mode !== "edit" ? (
              <div className={["FormActions", styles.FormActions].join(" ")}>
                <Button
                  btnType="Primary"
                  clicked={() => props.handleActiveStep(9)}
                >
                  Next
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <div>No Pricing found</div>
        )}
      </div>
      <DialogBox
        show={showDeleteDialogBox}
        confirm={confirmDelete}
        cancel={handleDelete}
        title="Delete Price"
        message="Are you sure you want to delete this Price?"
      />
      {newForm}
      <ToastContainer />
    </div>
  );
};

export default Prices;
