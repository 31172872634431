import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./View.module.css";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Button from "../../../components/Button/Button";
import HtmlContent from "../../../components/HtmlContent/HtmlContent";
import EditButton from "../../../components/EditButton/EditButton";

import AddNewIcon from "../../../assets/images/add-new-icon.svg";

const View = (props) => {
  const [data, setData] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [prices, setPrices] = useState([]);
  const [inventory, setInventory] = useState([]);

  const params = useParams();

  const fetchData = useCallback(() => {
    axios
      .get(`/products/${params.id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, [params]);

  const fetchDocuments = useCallback(() => {
    axios
      .get(`/products/${params.id}/documents`)
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [params]);

  const fetchCertificates = useCallback(() => {
    axios
      .get(`/products/${params.id}/certificates`)
      .then((response) => {
        setCertificates(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [params]);

  const fetchPhotos = useCallback(() => {
    axios
      .get(`/products/${params.id}/photos`)
      .then((response) => {
        setPhotos(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [params]);

  const fetchSizes = useCallback(() => {
    axios
      .get(`/products/${params.id}/sizes`)
      .then((response) => {
        setSizes(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [params]);

  const fetchPrices = useCallback(() => {
    axios
      .get(`/products/${params.id}/prices`)
      .then((response) => {
        setPrices(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [params]);

  const fetchInventory = useCallback(() => {
    axios
      .get(`/products/${params.id}/inventory`)
      .then((response) => {
        setInventory(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [params]);

  useEffect(() => {
    fetchData();
    fetchDocuments();
    fetchCertificates();
    fetchPhotos();
    fetchSizes();
    fetchPrices();
    fetchInventory();
  }, [
    fetchData,
    fetchDocuments,
    fetchCertificates,
    fetchPhotos,
    fetchSizes,
    fetchPrices,
    fetchInventory,
  ]);

  return (
    <div className={styles.View}>
      <Helmet>
        <title>View | Products | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Products</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            <li>View</li>
          </Breadcrumb>
        </div>
        <div className="ContentRight">
          <Button btnType="Success" elementType="link" link="/products/create">
            <img src={AddNewIcon} alt="New Product" /> <span>New</span>
          </Button>
        </div>
      </div>
      {data ? (
        <div className={["PageContent", styles.ProductOverview].join(" ")}>
          <div className={styles.DetailsContainer}>
            <div className="ContentHeader">
              <h2>Product Details</h2>
              <div className={styles.ContentActions}>
                <EditButton
                  clicked={() =>
                    props.history.push(`/products/${data.id}/edit`)
                  }
                />
              </div>
            </div>
            <div className={styles.DetailOverview}>
              <div className={styles.Detail}>
                <div>Name</div>
                <div>{data.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Alternate Name</div>
                <div>{data.alternate_name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Categories</div>
                <div>
                  <ul>
                    <li>Asdas sad</li>
                    <li>as da as asasd</li>
                  </ul>
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Applications</div>
                <div>
                  <ul>
                    <li>Asdas sad</li>
                    <li>as da as asasd</li>
                  </ul>
                </div>
              </div>
              <div className={[styles.Detail, styles.HtmlContent].join(" ")}>
                <div>Active Constituents / Principles</div>
                <div>
                  <HtmlContent html={data.highlights} />
                </div>
              </div>
              <div className={[styles.Detail, styles.HtmlContent].join(" ")}>
                <div>Description</div>
                <div>
                  <HtmlContent html={data.description} />
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Keywords</div>
                <div>{data.keywords}</div>
              </div>
              <div className={styles.Detail}>
                <div>Status</div>
                <div>{data.status.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Created At</div>
                <div>
                  {moment(data.created_at).format("DD MMM YYYY, h:mm A")}
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Updated At</div>
                <div>
                  {data.updated_at
                    ? moment(data.updated_at).format("DD MMM YYYY, h:mm A")
                    : "NA"}
                </div>
              </div>
            </div>
          </div>

          <div className={[styles.PhotosWrapper, styles.SubDetails].join(" ")}>
            <h3>Photos</h3>
            {photos.length ? (
              <div className={styles.Photos}>
                {photos.map((item) => {
                  return (
                    <div className={styles.Photo}>
                      <div className={styles.Image}>
                        <a href={item.image} target="_blank">
                          <img src={item.image} />
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>No Photos found</div>
            )}
          </div>

          <div
            className={[styles.DocumentsWrapper, styles.SubDetails].join(" ")}
          >
            <h3>Documents</h3>
            <div className={styles.Documents}>
              {documents.length ? (
                <table className="type-1">
                  <thead>
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((item) => {
                      return (
                        <tr>
                          <td width="300px">
                            <a href={item.document} target="_blank">
                              {item.name}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div>No Documents found</div>
              )}
            </div>
          </div>

          <div
            className={[styles.DocumentsWrapper, styles.SubDetails].join(" ")}
          >
            <h3>Certificates</h3>
            <div className={styles.Documents}>
              {certificates.length ? (
                <table className="type-1">
                  <thead>
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {certificates.map((item) => {
                      return (
                        <tr>
                          <td width="300px">
                            <a href={item.document} target="_blank">
                              {item.name}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div>No Certificates found</div>
              )}
            </div>
          </div>

          <div className={[styles.SizesWrapper, styles.SubDetails].join(" ")}>
            <h3>Sizes</h3>
            {sizes.length ? (
              <div className={styles.Sizes}>
                <table className="type-1">
                  <thead>
                    <tr>
                      <th>Weight</th>
                      <th>Package Type</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sizes.map((item) => {
                      return (
                        <tr>
                          <td width="300px">{item.weight}</td>
                          <td width="300px">{item.package_type.name}</td>
                          <td>
                            <td>{item.status.name}</td>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>No Sizes found</div>
            )}
          </div>

          <div className={[styles.PricesWrapper, styles.SubDetails].join(" ")}>
            <h3>Prices</h3>
            {prices.length ? (
              <div className={styles.Prices}>
                <table className="type-1">
                  <thead>
                    <tr>
                      <th>Region</th>
                      <th>Size</th>
                      <th>Quantity</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prices.map((item) => {
                      return (
                        <tr>
                          <td width="300px">{item.region.name}</td>
                          <td width="300px">{item.size.name}</td>
                          <td width="300px">{item.quantity}</td>
                          <td>{item.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>No Prices found</div>
            )}
          </div>

          <div
            className={[styles.InventoryWrapper, styles.SubDetails].join(" ")}
          >
            <h3>Inventory</h3>
            {inventory.length ? (
              <div className={styles.Inventory}>
                <table className="type-1">
                  <thead>
                    <tr>
                      <th>Warehouse</th>
                      <th>Stock</th>
                      <th>Created at</th>
                      <th>Updated at</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventory.map((item) => {
                      return (
                        <tr>
                          <td width="300px">{item.warehouse.name}</td>
                          <td width="300px">{item.stock}</td>
                          <td width="300px">
                            {moment(item.created_at).format(
                              "DD MMM YYYY, hh:mm A"
                            )}
                          </td>
                          <td>
                            {moment(item.updated_at).format(
                              "DD MMM YYYY, hh:mm A"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>No Inventory found</div>
            )}
          </div>
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default View;
