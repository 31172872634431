import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { TbUsers } from "react-icons/tb";
import { RiAdminLine } from "react-icons/ri";
import {
  MdOutlineShoppingCart,
  MdOutlineCategory,
  MdOutlineSettingsApplications,
} from "react-icons/md";
import { IoIosBarcode } from "react-icons/io";

import styles from "./Dashboard.module.css";

const Dashboard = () => {
  const [data, setData] = useState(null);

  const fetchData = useCallback(() => {
    axios.get("/stats/dashboard").then((response) => {
      setData(response.data);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.Dashboard}>
      <div className={["PageContent", styles.PageContent].join(" ")}>
        {data ? (
          <div className={styles.Cards}>
            <div className={styles.Card}>
              <div className={styles.Icon}>
                <TbUsers />
              </div>
              <div className={styles.Stat}>
                <Link to="/users">
                  <div className={styles.Count}>{data.users}</div>
                  <div className={styles.Title}>Users</div>
                </Link>
              </div>
            </div>

            <div className={styles.Card}>
              <div className={styles.Icon}>
                <MdOutlineShoppingCart />
              </div>
              <div className={styles.Stat}>
                <Link to="/orders">
                  <div className={styles.Count}>{data.orders}</div>
                  <div className={styles.Title}>Total Orders</div>
                </Link>
              </div>
            </div>

            <div className={styles.Card}>
              <div className={styles.Icon}>
                <MdOutlineShoppingCart />
              </div>
              <div className={styles.Stat}>
                <Link to="/orders">
                  <div className={styles.Count}>{data.pending_orders}</div>
                  <div className={styles.Title}>Pending Orders</div>
                </Link>
              </div>
            </div>

            <div className={styles.Card}>
              <div className={styles.Icon}>
                <MdOutlineShoppingCart />
              </div>
              <div className={styles.Stat}>
                <Link to="/orders">
                  <div className={styles.Count}>{data.completed_orders}</div>
                  <div className={styles.Title}>Completed Orders</div>
                </Link>
              </div>
            </div>

            <div className={styles.Card}>
              <div className={styles.Icon}>
                <MdOutlineShoppingCart />
              </div>
              <div className={styles.Stat}>
                <Link to="/product-enquires">
                  <div className={styles.Count}>{data.pending_product_enquires}</div>
                  <div className={styles.Title}>Pending Product Enquires</div>
                </Link>
              </div>
            </div>

            <div className={styles.Card}>
              <div className={styles.Icon}>
                <IoIosBarcode />
              </div>
              <div className={styles.Stat}>
                <Link to="/products">
                  <div className={styles.Count}>{data.products}</div>
                  <div className={styles.Title}>Products</div>
                </Link>
              </div>
            </div>

            <div className={styles.Card}>
              <div className={styles.Icon}>
                <MdOutlineCategory />
              </div>
              <div className={styles.Stat}>
                <Link to="/categories">
                  <div className={styles.Count}>{data.categories}</div>
                  <div className={styles.Title}>Categories</div>
                </Link>
              </div>
            </div>

            <div className={styles.Card}>
              <div className={styles.Icon}>
                <MdOutlineSettingsApplications />
              </div>
              <div className={styles.Stat}>
                <Link to="/applications">
                  <div className={styles.Count}>{data.applications}</div>
                  <div className={styles.Title}>Applications</div>
                </Link>
              </div>
            </div>

            <div className={styles.Card}>
              <div className={styles.Icon}>
                <RiAdminLine />
              </div>
              <div className={styles.Stat}>
                <Link to="/admins">
                  <div className={styles.Count}>{data.admins}</div>
                  <div className={styles.Title}>Admins</div>
                </Link>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Dashboard;
