import React from "react";
import { connect } from "react-redux";
import { FaUserCircle } from "react-icons/fa";
import { TbLogout } from "react-icons/tb";

import { logout } from "../../store/SignIn/SignIn.Actions";

import styles from "./Header.module.css";

const Header = (props) => {
  return (
    <div className={styles.Header}>
      {/* <SearchBox placeholder="Search.." /> */}
      <div className={styles.Actions}>
        <ul>
          <li className={styles.Profile}>
            <FaUserCircle />
            {localStorage.getItem("name")}
          </li>
          <li onClick={() => props.onLogout()} className={styles.Logout}>
            <TbLogout />
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.SignIn.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
