import React, { useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./Update.module.css";

import Overview from "../OverviewUpdate/OverviewUpdate";
import Categories from "../Categories/Categories";
import Applications from "../Applications/Applications";
import Documents from "../Documents/Documents";
import Certificates from "../Certificates/Cetificates";
import Photos from "../Photos/Photos";
import Sizes from "../Sizes/Sizes";
import Prices from "../Prices/Prices";
import Inventory from "../Inventory/Inventory";

const Update = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [productName, setProductName] = useState(null);

  const params = useParams();

  return (
    <div className={styles.Create}>
      <div className="PageContent DataForm">
        <div className="MainContent">
          <div className={styles.PageHeader}>
            <h1>{productName}</h1>
          </div>
          <div className={["FormWizard", styles.FormWizard].join(" ")}>
            <ul>
              <li
                className={activeStep === 1 ? "Active" : null}
                onClick={() => setActiveStep(1)}
              >
                <span>1</span>
                <span>Overview</span>
              </li>
              <li
                className={activeStep === 2 ? "Active" : null}
                onClick={() => setActiveStep(2)}
              >
                <span>2</span>
                <span>Categories</span>
              </li>
              <li
                draggable
                className={activeStep === 3 ? "Active" : null}
                onClick={() => setActiveStep(3)}
              >
                <span>3</span>
                <span>Applications</span>
              </li>
              <li
                className={activeStep === 4 ? "Active" : null}
                onClick={() => setActiveStep(4)}
              >
                <span>4</span>
                <span>Documents</span>
              </li>
              <li
                className={activeStep === 5 ? "Active" : null}
                onClick={() => setActiveStep(5)}
              >
                <span>5</span>
                <span>Certificates</span>
              </li>
              <li
                className={activeStep === 6 ? "Active" : null}
                onClick={() => setActiveStep(6)}
              >
                <span>6</span>
                <span>Photos</span>
              </li>
              <li
                className={activeStep === 7 ? "Active" : null}
                onClick={() => setActiveStep(7)}
              >
                <span>7</span>
                <span>Sizes</span>
              </li>
              <li
                className={activeStep === 8 ? "Active" : null}
                onClick={() => setActiveStep(8)}
              >
                <span>8</span>
                <span>Pricings</span>
              </li>
              <li
                className={activeStep === 9 ? "Active" : null}
                onClick={() => setActiveStep(9)}
              >
                <span>9</span>
                <span>Inventory</span>
              </li>
            </ul>
          </div>
          <div className="FormContent">
            {activeStep === 1 ? (
              <Overview productId={params.id} setProductName={setProductName} />
            ) : null}

            {activeStep === 2 ? (
              <Categories productId={params.id} mode="edit" />
            ) : null}

            {activeStep === 3 ? (
              <Applications productId={params.id} mode="edit" />
            ) : null}

            {activeStep === 4 ? (
              <Documents productId={params.id} mode="edit" />
            ) : null}

            {activeStep === 5 ? (
              <Certificates productId={params.id} mode="edit" />
            ) : null}

            {activeStep === 6 ? (
              <Photos productId={params.id} mode="edit" />
            ) : null}

            {activeStep === 7 ? (
              <Sizes productId={params.id} mode="edit" />
            ) : null}

            {activeStep === 8 ? (
              <Prices productId={params.id} mode="edit" />
            ) : null}

            {activeStep === 9 ? (
              <Inventory productId={params.id} mode="edit" />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
