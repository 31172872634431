import React, { Component } from "react";
import axios from "../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Categories.module.css";

import Button from "../../../components/Button/Button";
import Checkbox from "../../../components/FormElements/Checkbox/Checkbox";

class Index extends Component {
  state = {
    isLoading: false,
    categories: [],
    assignedCategories: [],
    finalCategories: [],
  };

  componentDidMount() {
    this.loadCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.categories !== this.state.categories) {
      this.loadAssignedCategories();
    }

    if (prevState.assignedCategories !== this.state.assignedCategories) {
      this.handleAssignedCategories();
    }
  }

  loadCategories = () => {
    axios
      .get("/categories?page=1&per-page=9999")
      .then((response) => {
        const categories = response.data.map((category) => {
          return {
            id: category.id,
            assignmentId: null,
            name: category.name,
            checked: false,
          };
        });

        this.setState({ categories: categories });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  loadAssignedCategories = () => {
    axios
      .get(
        `/products/${this.props.productId}/categories?page=1&per-page=9999&product_id=${this.props.productId}`
      )
      .then((response) => {
        this.setState({ assignedCategories: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAssignedCategories = () => {
    const updatedCategories = this.state.categories;
    this.state.categories.filter((category, i) => {
      this.state.assignedCategories.filter((assignedCategory) => {
        if (assignedCategory.category_id === category.id) {
          updatedCategories[i].checked = true;
          updatedCategories[i].assignmentId = assignedCategory.id;
        }
      });
    });

    this.setState({ finalCategories: updatedCategories });
  };

  handleCategoryChange = (val) => {
    this.state.finalCategories.filter((category, i) => {
      if (val === category.id) {
        const finalCategory = this.state.finalCategories[i];
        if (finalCategory.checked) {
          this.removeCategory(finalCategory.assignmentId, i);
        } else {
          this.assignCategory(finalCategory.id, i);
        }
      }
    });
  };

  assignCategory = (id, i) => {
    const formData = {
      category_id: id,
      product_id: this.props.productId,
    };

    axios
      .post(`/products/${this.props.productId}/categories`, formData)
      .then((response) => {
        toast.success(response.data.message);

        const updatedFinalCategories = this.state.finalCategories;
        updatedFinalCategories[i].assignmentId = response.data.id;
        updatedFinalCategories[i].checked = true;
        this.setState({
          finalCategories: updatedFinalCategories,
        });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  removeCategory = (id, i) => {
    axios
      .delete(`/products/${this.props.productId}/categories/${id}`)
      .then((response) => {
        toast.success(response.data.message);

        const updatedFinalCategories = this.state.finalCategories;
        updatedFinalCategories[i].assignmentId = null;
        updatedFinalCategories[i].checked = false;
        this.setState({
          finalCategories: updatedFinalCategories,
        });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  render() {
    let categories = "";
    if (this.state.finalCategories) {
      categories = this.state.finalCategories.map((category) => {
        return (
          <div className={styles.Category} key={category.id}>
            <Checkbox
              name={category.id}
              label={category.name}
              checked={category.checked}
              change={this.handleCategoryChange}
            />
          </div>
        );
      });
    }

    return (
      <div className={styles.Create}>
        <div className={styles.Categories}>{categories}</div>
        {this.state.finalCategories.some((item) => item.checked === true) &&
        this.props.mode !== "edit" ? (
          <div className={["FormActions", styles.FormActions].join(" ")}>
            <Button
              btnType="Primary"
              clicked={() => this.props.handleActiveStep(3)}
            >
              Next
            </Button>
          </div>
        ) : null}
        <ToastContainer />
      </div>
    );
  }
}

export default Index;
