import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./View.module.css";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Button from "../../../components/Button/Button";
import EditButton from "../../../components/EditButton/EditButton";

import AddNewIcon from "../../../assets/images/add-new-icon.svg";

const View = (props) => {
  const [data, setData] = useState(null);

  const params = useParams();

  const fetchData = useCallback(() => {
    axios
      .get(`/categories/${params.id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.View}>
      <Helmet>
        <title>View Details | Categories | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Categories</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/categories">Categories</Link>
            </li>
            <li>View</li>
          </Breadcrumb>
        </div>
        <div className="ContentRight">
          <Button
            btnType="Success"
            elementType="link"
            link="/categories/create"
          >
            <img src={AddNewIcon} alt="New User" /> <span>New</span>
          </Button>
        </div>
      </div>
      {data ? (
        <div className={["PageContent", styles.UserOverview].join(" ")}>
          <div className={styles.DetailsContainer}>
            <div className="ContentHeader">
              <h2>Category</h2>
              <div className={styles.ContentActions}>
                <EditButton
                  clicked={() =>
                    props.history.push(`/categories/${data.id}/edit`)
                  }
                />
              </div>
            </div>
            <div className={styles.DetailOverview}>
              <div className={styles.Detail}>
                <div>Name</div>
                <div>{data.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Keywords</div>
                <div>{data.keywords}</div>
              </div>
              <div className={styles.Detail}>
                <div>Photo</div>
                <div>
                  <img src={data.photo} width="100px" />
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Featured</div>
                <div>{data.featured.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Status</div>
                <div>{data.status.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Created at</div>
                <div>
                  {moment(data.created_at).format("DD MMM YYYY, h:mm A")}
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Updated at</div>
                <div>
                  {data.updated_at
                    ? moment(data.updated_at).format("DD MMM YYYY, h:mm A")
                    : "NA"}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default View;
