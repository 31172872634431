import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { Link } from "react-router-dom";
import axios from "../../../axios";
import moment from "moment";
import { Helmet } from "react-helmet";
import { DateRangePicker } from "react-date-range";

import styles from "./Index.module.css";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Button from "../../../components/Button/Button";
import Spinner from "../../../components/Spinner/Spinner";
import SearchBox from "../../../components/SearchBox/SearchBox";
import EditButton from "../../../components/EditButton/EditButton";
import Modal from "../../../components/Modal/Modal";

import AddNewIcon from "../../../assets/images/add-new-icon.svg";
import PrevIcon from "../../../assets/images/left-arrow-icon.svg";
import FirstIcon from "../../../assets/images/double-left-arrow-icon.svg";
import NextIcon from "../../../assets/images/right-arrow-icon.svg";
import LastIcon from "../../../assets/images/double-right-arrow-icon.svg";
import CalendarIcon from "../../../assets/images/calendar-circle-icon.svg";
import CloseIcon from "../../../assets/images/close-circle-icon.svg";

const Table = ({
  columns,
  data,
  onSort,
  fetchData,
  pageCount: controlledPageCount,
  recordCount,
  setSearchKeyword,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: "created_at",
            desc: true,
          },
        ],
      },
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    onSort({ sortBy, pageIndex, pageSize });
    fetchData({ pageIndex, pageSize, sortBy });
  }, [fetchData, pageIndex, pageSize, onSort, sortBy]);

  const searchTable = (event) => {
    if (event.key === "Enter") {
      setSearchKeyword(event.target.value);
    }
  };

  return (
    <>
      <div className="TableHeader">
        <div className="SearchTable">
          <SearchBox
            placeholder="Search by Name, Email or Phone"
            onKeyPress={(event) => searchTable(event)}
          />
        </div>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {recordCount > 0 ? (
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="10000">No records found!</td>
            </tr>
          </tbody>
        )}
      </table>
      <div className="TableFooter">
        <div className="TableRecordsCount">
          <div className="PageSize">
            <label>Show</label>
            <select
              className="FormControl"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50, 100, 500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="RecordsStartEnd">
            Showing {page.length} of {recordCount}
          </div>
        </div>
        <div className="TablePagination">
          <div className="PageCountStat">
            Page
            <span>
              {pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div>|</div>
          <div className="GoToPage">
            <label>Go to page</label>
            <input
              className="FormControl"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </div>
          <div className="PaginationNav">
            <Button
              type="button"
              btnType="Default"
              clicked={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <img src={FirstIcon} alt="First Page" />
            </Button>
            <Button
              type="button"
              btnType="Default"
              clicked={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <img src={PrevIcon} alt="Previous Page" />
            </Button>
            <Button
              type="button"
              btnType="Default"
              clicked={() => nextPage()}
              disabled={!canNextPage}
            >
              <img src={NextIcon} alt="Next Page" />
            </Button>
            <Button
              type="button"
              btnType="Default"
              clicked={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <img src={LastIcon} alt="Last Page" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const Index = (props) => {
  const [showCalendarModal, setShowCalendarModal] = useState(false);

  const handleCalendarModal = () => {
    setShowCalendarModal(!showCalendarModal);
  };

  const handleDateSelect = (item) => {
    setState([item]);
  };

  const submitFilterDates = () => {
    const startDate = moment(state[0].startDate).format("YYYY-MM-DD");
    const endDate = moment(state[0].endDate).format("YYYY-MM-DD");

    setFitlerDates({
      startDate: startDate,
      endDate: endDate,
    });

    handleCalendarModal();
    setTableId(tableId + 1);
  };

  const [filterDates, setFitlerDates] = useState({
    startDate: "",
    endDate: "",
  });

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const calendar = showCalendarModal ? (
    <Modal
      show={showCalendarModal}
      modalClosed={handleCalendarModal}
      width="960px"
    >
      <DateRangePicker
        onChange={(item) => handleDateSelect(item.selection)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
      />
      <div className={styles.SubmitDatesBtn}>
        <Button btnType="Primary" clicked={submitFilterDates}>
          Submit
        </Button>
      </div>
    </Modal>
  ) : null;

  const resetFilterDates = () => {
    setFitlerDates({
      startDate: "",
      endDate: "",
    });
  };

  const [tableId, setTableId] = useState(1);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Registered At",
        id: "created_at",
        accessor: (d) => moment(d.created_at).format("DD MMM YYYY, hh:mm A"),
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell }) => [
          <EditButton
            clicked={() =>
              props.history.push(`/admins/${cell.row.values.id}/edit`)
            }
          />,
        ],
        disableSortBy: true,
      },
    ],
    [props.history]
  );

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [recordCount, setRecordCount] = useState(0);
  const [startRecord, setStartRecord] = useState(0);
  const [endRecord, setEndRecord] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const fetchIdRef = useRef(0);

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      const fetchId = ++fetchIdRef.current;

      let orderBy = {
        order: "created_at",
        by: "DESC",
      };

      if (sortBy.length) {
        orderBy = {
          order: sortBy[0].id,
          by: sortBy[0].desc ? "DESC" : "ASC",
        };
      }

      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        axios
          .get(
            "/admins?per-page=" +
              pageSize +
              "&page=" +
              (pageIndex + 1) +
              "&keyword=" +
              searchKeyword +
              "&start_date=" +
              filterDates.startDate +
              "&end_date=" +
              filterDates.endDate +
              "&order=" +
              orderBy.order +
              "&by=" +
              orderBy.by
          )
          .then((response) => {
            setData(response.data);
            setPageCount(response.headers["x-pagination-page-count"]);
            setRecordCount(response.headers["x-pagination-total-count"]);
            setStartRecord(response.headers["x-pagination-start-record"]);
            setEndRecord(response.headers["x-pagination-end-Record"]);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    },
    [searchKeyword, filterDates]
  );

  const handleSort = useCallback(() => {
    setLoading(true);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Users | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Admins</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/admins">Admins</Link>
            </li>
          </Breadcrumb>
        </div>
        <div className="ContentRight">
          <Button btnType="Success" elementType="link" link="/admins/create">
            <img src={AddNewIcon} alt="New" /> <span>New</span>
          </Button>
        </div>
      </div>
      <div className="PageContent DataTable">
        <div className={styles.DateRangeFilter}>
          {filterDates.startDate && filterDates.endDate ? (
            <div className={styles.SelectedFilterDates}>
              <div className={styles.Date}>
                {moment(filterDates.startDate).format("DD MMM YYYY") +
                  " - " +
                  moment(filterDates.endDate).format("DD MMM YYYY")}
              </div>
              <img
                src={CloseIcon}
                alt="Reset Date Filter"
                onClick={() => resetFilterDates()}
              />
            </div>
          ) : null}
          <img
            src={CalendarIcon}
            className={styles.CalendarIcon}
            alt="Calendar"
            onClick={() => handleCalendarModal()}
          />
        </div>
        {calendar}

        <Table
          key={tableId}
          columns={columns}
          data={data}
          onSort={handleSort}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          recordCount={recordCount}
          startRecord={startRecord}
          endRecord={endRecord}
          setSearchKeyword={setSearchKeyword}
        />
      </div>
      <Spinner isLoading={loading} />
    </React.Fragment>
  );
};

export default Index;
