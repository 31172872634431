import React from "react";

import Input from "./Input/Input";
import Textarea from "./Textarea/Textarea";
import Password from "./Password/Password";
import PasswordStrength from "./PasswordStrength/PasswordStrength";
import Select from "./Select/Select";
import SelectAsync from "./SelectAsync/SelectAsync";
import DatePicker from "./DatePicker/DatePicker";
import FileInput from "./FileInput/FileInput";
import Switch from "./Switch/Switch";
import RichTextInput from "./RichTextInput/RichTextInput";

const FormikControl = (props) => {
  const { control, ...rest } = props;

  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "password":
      return <Password {...rest} />;
    case "passwordStrength":
      return <PasswordStrength {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "selectAsync":
      return <SelectAsync {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
    case "fileInput":
      return <FileInput {...rest} />;
    case "switch":
      return <Switch {...rest} />;
    case "richTextInput":
      return <RichTextInput {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
