import React, { useCallback, useState, useEffect } from "react";
import axios from "../../../axios";
import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Inventory.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import EditButton from "../../../components/EditButton/EditButton";
import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Modal from "../../../components/Modal/Modal";
import DialogBox from "../../../components/DialogBox/DialogBox";

import AddNewIcon from "../../../assets/images/add-new-icon.svg";

const Inventory = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  // load Warehouses
  const loadWarehouses = (inputValue) => {
    return axios
      .get(`/warehouses?page=1&per-page=20&query=${inputValue}`)
      .then((res) => res.data);
  };

  // load Sizes
  const loadSizes = (inputValue) => {
    const sizes = [];
    return axios
      .get(
        `/products/${props.productId}/sizes?page=1&per-page=20&query=${inputValue}`
      )
      .then((res) => {
        res.data.map((item) => {
          sizes.push({
            id: item.id,
            name: item.weight + "kg " + item.package_type.name,
          });
        });
        return sizes;
      });
  };

  const fetchData = useCallback(() => {
    axios
      .get(`/products/${props.productId}/inventory`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [props]);

  const [showDeleteDialogBox, setShowDeleteDialogBox] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);

  const handleNewForm = () => {
    const formValues = {
      id: "",
      warehouse_id: "",
      size_id: "",
      sku: "",
      stock: "",
      low_stock_threshold: "",
    };

    setFormValues(formValues);
    setEditMode(false);
    setShowNewForm(!showNewForm);
  };

  const handleEdit = (item) => {
    const formValues = {
      ...item,
      warehouse_id: item.warehouse.id.toString(),
      size_id: item.size.id.toString(),
    };
    setFormValues(formValues);
    setEditMode(true);
    setShowNewForm(true);
  };

  const initialValues = {
    warehouse_id: formValues ? formValues.warehouse_id : "",
    size_id: formValues ? formValues.size_id : "",
    sku: formValues ? formValues.sku : "",
    stock: formValues ? formValues.stock : "",
    low_stock_threshold: formValues ? formValues.low_stock_threshold : "",
  };

  const validationSchema = Yup.object({
    warehouse_id: Yup.string().required("Warehouse is required"),
    size_id: Yup.string().required("Size is required"),
    sku: Yup.string().required("SKU is required"),
    stock: Yup.string().required("Stock is required"),
    low_stock_threshold: Yup.string().required("Stock is required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    if (!editMode) {
      axios
        .post(`/products/${props.productId}/inventory`, values)
        .then((response) => {
          toast.success(response.data.message);
          fetchData();
          setShowNewForm(false);
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      axios
        .put(`/products/${props.productId}/inventory/${formValues.id}`, values)
        .then((response) => {
          toast.success(response.data.message);
          fetchData();
          setShowNewForm(false);
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const newForm = showNewForm ? (
    <Modal
      show={showNewForm}
      modalClosed={handleNewForm}
      title={`${editMode ? "EDIT" : "ADD"}
      inventory`}
    >
      <div className="FormContainer ModalForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form>
                <div className={["FormBody", styles.FormBody].join(" ")}>
                  <FormikControl
                    control="selectAsync"
                    label="Warehouse *"
                    name="warehouse_id"
                    value={formValues.warehouse}
                    setFieldValue={setFieldValue}
                    loadOptions={loadWarehouses}
                  />

                  <FormikControl
                    control="selectAsync"
                    label="Size *"
                    name="size_id"
                    value={formValues.size}
                    setFieldValue={setFieldValue}
                    loadOptions={loadSizes}
                  />

                  <FormikControl
                    control="input"
                    type="text"
                    label="SKU *"
                    name="sku"
                  />

                  <FormikControl
                    control="input"
                    type="text"
                    label="Stock *"
                    name="stock"
                  />

                  <FormikControl
                    control="input"
                    type="text"
                    label="Low Stock Threshold *"
                    name="low_stock_threshold"
                  />
                </div>
                <div className="FormActions">
                  <Button
                    btnType="Primary"
                    type="Submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  ) : null;

  const handleDelete = (id = null) => {
    setShowDeleteDialogBox(!showDeleteDialogBox);
    setActiveItem(id);
  };

  const confirmDelete = () => {
    axios
      .delete(`/products/${props.productId}/inventory/${activeItem}`)
      .then((response) => {
        toast.success(response.data.message);
        setShowDeleteDialogBox(false);
        fetchData();
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.ProductInventory}>
      <div className={styles.PageActions}>
        <Button btnType="Success" clicked={handleNewForm}>
          <img src={AddNewIcon} alt="New" /> <span>Add Inventory</span>
        </Button>
      </div>
      <div className={styles.InventoryWrapper}>
        {data.length ? (
          <>
            <div className={styles.Inventory}>
              <table className="type-1">
                <thead>
                  <tr>
                    <th>Warehouse</th>
                    <th>Size</th>
                    <th>SKU</th>
                    <th>Stock</th>
                    <th>Low Stock Threshold</th>
                    <th>Created at</th>
                    <th>Updated at</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => {
                    return (
                      <tr>
                        <td>{item.warehouse.name}</td>
                        <td>{item.size.name}</td>
                        <td>{item.sku}</td>
                        <td>{item.stock}</td>
                        <td>{item.low_stock_threshold}</td>
                        <td>
                          {moment(item.created_at).format(
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </td>
                        <td>
                          {moment(item.updated_at).format(
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </td>
                        <td className="ActionsColumn">
                          <EditButton clicked={() => handleEdit(item)} />
                          <DeleteButton clicked={() => handleDelete(item.id)} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {props.mode !== "edit" ? (
              <div className={["FormActions", styles.FormActions].join(" ")}>
                <Button
                  btnType="Primary"
                  clicked={() => props.handleActiveStep(1)}
                >
                  Finish
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <div>No Inventory found</div>
        )}
      </div>
      <DialogBox
        show={showDeleteDialogBox}
        confirm={confirmDelete}
        cancel={handleDelete}
        title="Delete Inventory"
        message="Are you sure you want to delete this Entry?"
      />
      {newForm}
      <ToastContainer />
    </div>
  );
};

export default Inventory;
