import React from "react";
import { Route } from "react-router-dom";

import styles from "./SignInLayout.module.css";

import Logo from "../../../assets/images/logo.svg";

const SignInLayout = ({ component: Component }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Container}>
        <div className={styles.Logo}>
          <img src={Logo} alt={process.env.REACT_APP_NAME} width="200px" />
        </div>

        <Route component={Component} />
      </div>
    </div>
  );
};

export default SignInLayout;
