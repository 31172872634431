import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Create.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";

const Create = () => {
  const [formId, setFormId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    name: "",
    keywords: "",
    featured: 0,
    photo: "",
    status: 0,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    keywords: Yup.string().required("Keywords is required"),
    photo: Yup.mixed().required("Photo is required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("keywords", values.keywords);
    formData.append("featured", values.featured);
    formData.append("status", values.status);
    formData.append("photo", values.photo);

    axios
      .post("/categories", formData)
      .then((response) => {
        toast.success(response.data.message);
        setFormId(formId + 1);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const statusOptions = [
    { value: "0", label: "Disabled" },
    { value: "1", label: "Enabled" },
  ];

  const featuredOptions = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  return (
    <div className={styles.Create}>
      <Helmet>
        <title>New | Categories | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Categories</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/categories">Categories</Link>
            </li>
            <li>New</li>
          </Breadcrumb>
        </div>
      </div>
      <div className="PageContent DataForm">
        <div className="ContentHeader">
          <div className="Title">
            <h1>Add Category</h1>
          </div>
        </div>
        <div className="MainContent">
          <div className="FormContainer">
            <Formik
              key={formId}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ setFieldValue }) => {
                return (
                  <Form>
                    <div className={["FormBody", styles.FormBody].join(" ")}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Name *"
                        name="name"
                        maxLength="100"
                      />

                      <FormikControl
                        control="input"
                        type="text"
                        label="Keywords *"
                        name="keywords"
                      />

                      <FormikControl
                        control="select"
                        label="Featured *"
                        name="featured"
                        value="0"
                        options={featuredOptions}
                        setFieldValue={setFieldValue}
                      />

                      <FormikControl
                        control="select"
                        label="Status *"
                        name="status"
                        value="0"
                        options={statusOptions}
                        setFieldValue={setFieldValue}
                      />

                      <FormikControl
                        control="fileInput"
                        label="Photo *"
                        name="photo"
                        accept=".png, .jpg"
                        setFieldValue={setFieldValue}
                        fileFormats="PNG, JPG"
                        maxFileSize="2MB"
                        value=""
                      />
                    </div>

                    <div
                      className={["FormActions", styles.FormActions].join(" ")}
                    >
                      <Button
                        btnType="Primary"
                        type="Submit"
                        width="W100"
                        isLoading={isLoading}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Create;
