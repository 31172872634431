import React, { useCallback, useState, useEffect } from "react";
import axios from "../../../axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Sizes.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import EditButton from "../../../components/EditButton/EditButton";
import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Modal from "../../../components/Modal/Modal";
import DialogBox from "../../../components/DialogBox/DialogBox";

import AddNewIcon from "../../../assets/images/add-new-icon.svg";

const Sizes = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const packageOptions = [
    { value: "1", label: "Drum" },
    { value: "2", label: "Box" },
  ];

  const statusOptions = [
    { value: "0", label: "Disabled" },
    { value: "1", label: "Enabled" },
  ];

  const fetchData = useCallback(() => {
    axios
      .get(`/products/${props.productId}/sizes`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [props]);

  const [showDeleteDialogBox, setShowDeleteDialogBox] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);

  const handleNewForm = () => {
    const formValues = {
      id: "",
      weight: "",
      package_type: "1",
      status: "0",
    };

    setFormValues(formValues);
    setEditMode(false);
    setShowNewForm(!showNewForm);
  };

  const handleEdit = (item) => {
    const formValues = {
      ...item,
      package_type: item.package_type.id.toString(),
      status: item.status.id.toString(),
    };
    setFormValues(formValues);
    setEditMode(true);
    setShowNewForm(true);
  };

  const initialValues = {
    weight: formValues ? formValues.weight : "",
    package_type: formValues ? formValues.package_type : "",
    status: formValues ? formValues.status : "",
  };

  const validationSchema = Yup.object({
    weight: Yup.string().required("Weight is required"),
    package_type: Yup.string().required("Package Type is required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    if (!editMode) {
      axios
        .post(`/products/${props.productId}/sizes`, values)
        .then((response) => {
          toast.success(response.data.message);
          fetchData();
          setShowNewForm(false);
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      axios
        .put(`/products/${props.productId}/sizes/${formValues.id}`, values)
        .then((response) => {
          toast.success(response.data.message);
          fetchData();
          setShowNewForm(false);
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const newForm = showNewForm ? (
    <Modal
      show={showNewForm}
      modalClosed={handleNewForm}
      title={`${editMode ? "EDIT" : "ADD"}
        Size`}
    >
      <div className="FormContainer ModalForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form>
                <div className={["FormBody", styles.FormBody].join(" ")}>
                  <FormikControl
                    control="input"
                    type="text"
                    label="Weight *"
                    name="weight"
                  />

                  <FormikControl
                    control="select"
                    label="Package Type *"
                    name="package_type"
                    value={formValues ? formValues.package_type : ""}
                    options={packageOptions}
                    setFieldValue={setFieldValue}
                  />

                  <FormikControl
                    control="select"
                    label="Status *"
                    name="status"
                    value={formValues ? formValues.status : ""}
                    options={statusOptions}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div className="FormActions">
                  <Button
                    btnType="Primary"
                    type="Submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  ) : null;

  const handleDelete = (id = null) => {
    setShowDeleteDialogBox(!showDeleteDialogBox);
    setActiveItem(id);
  };

  const confirmDelete = () => {
    axios
      .delete(`/products/${props.productId}/sizes/${activeItem}`)
      .then((response) => {
        toast.success(response.data.message);
        setShowDeleteDialogBox(false);
        fetchData();
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.ProductSizes}>
      <div className={styles.PageActions}>
        <Button btnType="Success" clicked={handleNewForm}>
          <img src={AddNewIcon} alt="New" /> <span>Add Size</span>
        </Button>
      </div>
      <div className={styles.SizesWrapper}>
        {data.length ? (
          <>
            <div className={styles.Sizes}>
              <table className="type-1">
                <thead>
                  <tr>
                    <th>Weight</th>
                    <th>Package Type</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => {
                    return (
                      <tr>
                        <td>{item.weight}</td>
                        <td>{item.package_type.name}</td>
                        <td>
                          <td>{item.status.name}</td>
                        </td>
                        <td className="ActionsColumn">
                          <EditButton clicked={() => handleEdit(item)} />
                          <DeleteButton clicked={() => handleDelete(item.id)} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {props.mode !== "edit" ? (
              <div className={["FormActions", styles.FormActions].join(" ")}>
                <Button
                  btnType="Primary"
                  clicked={() => props.handleActiveStep(8)}
                >
                  Next
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <div>No Sizes found</div>
        )}
      </div>
      <DialogBox
        show={showDeleteDialogBox}
        confirm={confirmDelete}
        cancel={handleDelete}
        title="Delete Size"
        message="Are you sure you want to delete this Size?"
      />
      {newForm}
      <ToastContainer />
    </div>
  );
};

export default Sizes;
