import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Update.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";

const Update = () => {
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();

  const fetchData = useCallback(() => {
    axios
      .get(`/applications/${params.id}`)
      .then((response) => {
        const data = response.data;
        data.status = data.status.id.toString();
        setData({ ...data });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialValues = {
    ...data,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    axios
      .put(`/applications/${data.id}`, values)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const statusOptions = [
    { value: "0", label: "Disabled" },
    { value: "1", label: "Enabled" },
  ];

  return (
    <div className={styles.Create}>
      <Helmet>
        <title>Edit | Applications | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Applications</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/applications">Applications</Link>
            </li>
            <li>Edit</li>
          </Breadcrumb>
        </div>
      </div>
      <div className="PageContent DataForm">
        <div className="ContentHeader">
          <div className="Title">
            <h1>Edit Application</h1>
          </div>
        </div>
        <div className="MainContent">
          <div className="FormContainer">
            {data ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setFieldValue }) => {
                  return (
                    <Form>
                      <div className={["FormBody", styles.FormBody].join(" ")}>
                        <FormikControl
                          control="input"
                          type="text"
                          label="Name *"
                          name="name"
                          maxLength="100"
                        />

                        <FormikControl
                          control="select"
                          label="Status *"
                          name="status"
                          value={data.status}
                          options={statusOptions}
                          setFieldValue={setFieldValue}
                        />
                      </div>

                      <div
                        className={["FormActions", styles.FormActions].join(
                          " "
                        )}
                      >
                        <Button
                          btnType="Primary"
                          type="Submit"
                          width="W100"
                          isLoading={isLoading}
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : null}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Update;
