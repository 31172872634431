import React, { useState } from "react";
import { ErrorMessage } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import styles from "./RichTextInput.module.css";

import ErrorMsg from "../../ErrorMsg/ErrorMsg";

const RichTextInput = (props) => {
  let { name, value, setFieldValue } = props;

  const [editorData, setEditorData] = useState(value);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFieldValue(name, data);
    setEditorData(data);
  };

  const label = props.label ? (
    <label htmlFor={name} className="ControlLabel">
      {props.label}
    </label>
  ) : null;

  return (
    <div className="FormGroup">
      {label}
      <div className={styles.RichTextInput}>
        <CKEditor
          editor={ClassicEditor}
          data={editorData}
          onBlur={handleEditorChange}
        />
      </div>
      <ErrorMessage name={name} component={ErrorMsg} />
    </div>
  );
};

export default RichTextInput;
