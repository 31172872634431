import SignInTypes from "./SignIn.Types";

const INITIAL_STATE = {
  token: null,
  isAuthenticated: false,
  error: null,
  loading: false,
};

const signInReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SignInTypes.SIGN_IN_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SignInTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        token: action.token,
        isAuthenticated: true,
        error: null,
        loading: false,
      };
    case SignInTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SignInTypes.LOGOUT_SESSION:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default signInReducer;
