import React, { useRef } from "react";
import DateView from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from "formik";

import styles from "./DatePicker.module.css";
import "./DatePicker.css";

import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import calendarIcon from "../../../assets/images/calendar-icon.svg";

const DatePicker = (props) => {
  let {
    label,
    name,
    minDate,
    maxDate,
    setMinDate,
    setMaxDate,
    setFieldValue,
    ...rest
  } = props;

  const type = props.alignType ? props.alignType : "";

  const inputRef = useRef(null);

  const handleSetDate = (val) => {
    setFieldValue(name, val);

    if (setMinDate) {
      setMinDate(val);
    }

    if (setMaxDate) {
      setMaxDate(val);
    }
  };

  return (
    <div className={["FormGroup", styles[type]].join(" ")}>
      <div className={styles.Field}>
        <label htmlFor={name} className="ControlLabel">
          {label}
        </label>
        <Field name={name}>
          {({ field }) => {
            const { value } = field;
            console.log("value", value);
            return (
              <DateView
                id={name}
                className={["FormControl", styles.FormControl].join(" ")}
                name={name}
                dateFormat="dd-MM-yyyy"
                selected={value}
                onChange={(val) => handleSetDate(val)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                minDate={minDate ? minDate : ""}
                maxDate={maxDate ? maxDate : ""}
                autoComplete="off"
                ref={inputRef}
                placeholderText={props.placeholder}
                {...rest}
              />
            );
          }}
        </Field>

        <img
          src={calendarIcon}
          alt="Calendar Icon"
          className={[styles.CalendarIcon, "CalendarIcon"].join(" ")}
          onClick={() => inputRef.current.deferFocusInput()}
        />
      </div>
      <ErrorMessage name={name} component={ErrorMsg} type={type} />
    </div>
  );
};

export default DatePicker;
