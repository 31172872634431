import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./SignInForm.module.css";

import FormikControl from "../FormikControl/FormikControl";
import Button from "../Button/Button";

import { signIn } from "../../store/SignIn/SignIn.Actions";

const SignInForm = (props) => {
  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .test("regex", "Invalid Email ID", (val) => {
        const regExpEmail = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);
        return regExpEmail.test(val);
      }),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = (values) => {
    props.onSignIn(values.email, values.password);
  };

  if (props.error) {
    if (props.error.code === 2001) {
      const errors = props.error.errors;
      toast.error(Object.values(errors[0])[0], {
        toastId: "errorMsg",
      });
    } else {
      toast.error(props.error.message, {
        toastId: "errorMsg",
      });
    }
  }

  return (
    <React.Fragment>
      <div
        className={["FormContainer ModalForm", styles.FormContainer].join(" ")}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form className={styles.SignInForm}>
              <div className={["FormBody", styles.FormBody].join(" ")}>
                <FormikControl
                  control="input"
                  type="text"
                  label="Email"
                  name="email"
                />

                <FormikControl
                  control="password"
                  type="password"
                  label="Password"
                  name="password"
                />
              </div>

              <ToastContainer />

              <div className="FormActions">
                <Button btnType="Primary" type="submit" width="W100">
                  Sign In
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.SignIn.loading,
    error: state.SignIn.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSignIn: (email, password) => dispatch(signIn(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
