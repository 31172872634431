import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FiEdit2 } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";

import styles from "./View.module.css";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import DialogBox from "../../../components/DialogBox/DialogBox";

const View = () => {
  const [data, setData] = useState(null);
  const [showPaymentStatusDialog, setShowPaymentStatusDialog] = useState(false);
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [showShippedForm, setShowShippedForm] = useState(false);
  const [status, setStatus] = useState(null);

  const params = useParams();

  const fetchData = useCallback(() => {
    axios
      .get(`/orders/${params.id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, [params]);

  const handlePaymentStatus = () => {
    setShowPaymentStatusDialog(!showPaymentStatusDialog);
  };

  const handlePaymentStatusConfirm = () => {
    const values = {
      id: data.id,
      payment_status: 1,
    };

    axios
      .put(`/orders/${data.id}`, values)
      .then((response) => {
        toast.success(response.data.message);
        setShowPaymentStatusDialog(false);
        fetchData();
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const handleStatusUpdate = (status) => {
    setShowStatusDialog(!showStatusDialog);
    setStatus(status);
  };

  const updateStatus = () => {
    const values = {
      status: status,
    };
    axios
      .put(`/orders/${params.id}`, values)
      .then((response) => {
        fetchData();
        toast.success(response.data.message);
        setShowStatusDialog(false);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleShippedForm = () => {
    setShowShippedForm(!showShippedForm);
  };

  const initialValues = {
    courier: "",
    shipping_tracking_no: "",
  };

  const validationSchema = Yup.object({
    courier: Yup.string().required("Courier is required"),
    shipping_tracking_no: Yup.string().required(
      "Shipping Tracking No is required"
    ),
  });

  const onSubmit = (values) => {
    values.status = 3;
    axios
      .put(`/orders/${params.id}`, values)
      .then((response) => {
        toast.success(response.data.message);
        fetchData();
        setShowShippedForm(false);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const shippedForm = showShippedForm ? (
    <Modal
      show={showShippedForm}
      modalClosed={handleShippedForm}
      title="Update Status"
    >
      <div className="FormContainer ModalForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <div className={["FormBody", styles.FormBody].join(" ")}>
                  <FormikControl
                    control="input"
                    type="text"
                    label="Courier *"
                    name="courier"
                    maxLength="50"
                  />

                  <FormikControl
                    control="input"
                    type="text"
                    label="Shipping Tracking No *"
                    name="shipping_tracking_no"
                    maxLength="50"
                  />
                </div>
                <div className="FormActions">
                  <Button
                    btnType="Primary"
                    type="Submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  ) : null;

  return (
    <div className={styles.View}>
      <Helmet>
        <title>View Details | Orders | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Orders</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/orders">Orders</Link>
            </li>
            <li>View</li>
          </Breadcrumb>
        </div>
      </div>
      {data ? (
        <div className={["PageContent", styles.UserOverview].join(" ")}>
          <div className={styles.DetailsContainer}>
            <div className="ContentHeader">
              <h2>Order</h2>
              <div className="HeaderActions">
                {data.payment_status.id === 0 && data.status.id === 1 ? (
                  <Button
                    btnType="Success"
                    clicked={() => handlePaymentStatus()}
                  >
                    <IoIosCheckmarkCircle /> <span>Mark as Paid</span>
                  </Button>
                ) : null}
                {data.payment_status.id === 1 && data.status.id === 1 ? (
                  <Button
                    btnType="Success"
                    clicked={() => handleStatusUpdate(2)}
                  >
                    <FiEdit2 />
                    <span>Change status to Processing</span>
                  </Button>
                ) : null}
                {data.status.id === 2 ? (
                  <Button btnType="Success" clicked={() => handleShippedForm()}>
                    <FiEdit2 />
                    <span>Change status to Shipped</span>
                  </Button>
                ) : null}
                {data.status.id === 3 ? (
                  <Button
                    btnType="Success"
                    clicked={() => handleStatusUpdate(4)}
                  >
                    <FiEdit2 />
                    <span>Change status to Delivered</span>
                  </Button>
                ) : null}
                {data.status.id <= 2 && data.payment_status.id === 0 ? (
                  <Button
                    btnType="Default"
                    clicked={() => handleStatusUpdate(5)}
                  >
                    <MdOutlineCancel />
                    <span>Cancel Order</span>
                  </Button>
                ) : null}
              </div>
            </div>
            <div className={styles.DetailOverview}>
              <div className={styles.Detail}>
                <div>Order ID</div>
                <div>{data.ref_id}</div>
              </div>
              <div className={styles.Detail}>
                <div>Region</div>
                <div>{data.region_name}</div>
              </div>
              <div className={styles.Detail}>
                <div>User</div>
                <div>
                  <Link
                    to={`/users/${data.user.id}/view`}
                  >
                    {data.user.name}
                  </Link>
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Product</div>
                <div>{data.product.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Size</div>
                <div>{data.size.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Quantity</div>
                <div>{data.quantity}</div>
              </div>
              <div className={styles.Detail}>
                <div>Price</div>
                <div>
                  {data.currency} {data.price}
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Total Amount</div>
                <div>
                  {data.currency} {data.total_amount}
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Shipping Address</div>
                <div className={styles.ParaContent}>
                  <p>{data.street_address_1}</p>{" "}
                  {data.street_address_2 ? <p>data.street_address_2</p> : null}
                  <p>
                    {data.city}, {data.state_province}, {data.zip_postal_code},{" "}
                    {data.country}
                  </p>
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Courier</div>
                <div>{data.courier}</div>
              </div>
              <div className={styles.Detail}>
                <div>Shipping Tracking No</div>
                <div>{data.shipping_tracking_no}</div>
              </div>
              <div className={styles.Detail}>
                <div>Payment Status</div>
                <div>{data.payment_status.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Status</div>
                <div>{data.status.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Ordered at</div>
                <div>
                  {moment(data.created_at).format("DD MMM YYYY, h:mm A")}
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Updated at</div>
                <div>
                  {data.updated_at
                    ? moment(data.updated_at).format("DD MMM YYYY, h:mm A")
                    : "NA"}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <DialogBox
        show={showPaymentStatusDialog}
        confirm={handlePaymentStatusConfirm}
        cancel={handlePaymentStatus}
        title="Update Payment Status"
        message="Are you sure you want to mark this order as Paid?"
      />

      <DialogBox
        show={showStatusDialog}
        confirm={updateStatus}
        cancel={handleStatusUpdate}
        title={status === 5 ? "Cancel Order" : "Change Status"}
        message={
          status === 5
            ? "Are you sure you want to cancel the order?"
            : "Are you sure you want to change the status?"
        }
      />
      <ToastContainer />
      {shippedForm}
    </div>
  );
};

export default View;
