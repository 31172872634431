import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Update.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";

const Update = () => {
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");

  const params = useParams();

  const fetchData = useCallback(() => {
    axios
      .get(`/admins/${params.id}`)
      .then((response) => {
        const data = response.data;
        data.status = data.status.id.toString();
        data.role = data.role.id.toString();
        setData({ ...data });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialValues = {
    ...data,
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required("First Name is required")
      .trim()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field"),
    last_name: Yup.string()
      .required("Last Name is required")
      .trim()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field"),
    email: Yup.string()
      .required("Email is required")
      .matches(/^[a-zA-Z0-9.@_ ]+$/, "Invalid Email ID")
      .test("regex", "Invalid Email ID", (val) => {
        const regExpEmail = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);
        return regExpEmail.test(val);
      }),
    role: Yup.string().required("Role is required"),
    password: Yup.string()
      .max(14, "Password must contain only 14 characters")
      .min(7, "Password must be at least 7 characters"),
    status: Yup.string().required("Status is required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    axios
      .put(`/admins/${data.id}`, values)
      .then((response) => {
        toast.success(response.data.message);
        setPassword("");
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const statusOptions = [
    { value: "0", label: "Disabled" },
    { value: "1", label: "Enabled" },
  ];

  const roleOptions = [
    { value: "1", label: "Superadmin" },
    { value: "2", label: "Admin" },
    { value: "3", label: "General" },
  ];

  return (
    <div className={styles.Create}>
      <Helmet>
        <title>Edit | Admins | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Admins</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/admins">Admins</Link>
            </li>
            <li>Edit</li>
          </Breadcrumb>
        </div>
      </div>
      <div className="PageContent DataForm">
        <div className="ContentHeader">
          <div className="Title">
            <h1>Edit Admin</h1>
          </div>
        </div>
        <div className="MainContent">
          <div className="FormContainer">
            {data ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setFieldValue }) => {
                  return (
                    <Form>
                      <div className={["FormBody", styles.FormBody].join(" ")}>
                        <FormikControl
                          control="input"
                          type="text"
                          label="First Name *"
                          name="first_name"
                          maxLength="30"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Last Name *"
                          name="last_name"
                          maxLength="30"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Email *"
                          name="email"
                          maxLength="150"
                        />

                        <FormikControl
                          control="select"
                          label="Role *"
                          name="role"
                          options={roleOptions}
                          value={data.role}
                          setFieldValue={setFieldValue}
                        />

                        <FormikControl
                          control="passwordStrength"
                          type="password"
                          label="Password"
                          name="password"
                          setPassword={setPassword}
                          password={password}
                        />

                        <FormikControl
                          control="select"
                          label="Status *"
                          name="status"
                          options={statusOptions}
                          value={data.status}
                          setFieldValue={setFieldValue}
                        />
                      </div>

                      <div
                        className={["FormActions", styles.FormActions].join(
                          " "
                        )}
                      >
                        <Button
                          btnType="Primary"
                          type="Submit"
                          width="W100"
                          isLoading={isLoading}
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : null}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Update;
