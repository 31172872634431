import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Update.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";

const Update = () => {
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();

  const fetchData = useCallback(() => {
    axios
      .get(`/warehouses/${params.id}`)
      .then((response) => {
        const data = response.data;
        data.region_id = data.region.id.toString();
        setData({ ...data });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialValues = {
    region_id: data.region_id,
    name: data.name,
    address: data.address,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    axios
      .put(`/warehouses/${data.id}`, values)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // load Regions
  const loadRegions = (inputValue) => {
    return axios
      .get(`/regions?page=1&per-page=20&query=${inputValue}`)
      .then((res) => res.data);
  };

  return (
    <div className={styles.Create}>
      <Helmet>
        <title>Edit | Warehouses | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Warehouses</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/warehouses">Warehouses</Link>
            </li>
            <li>Edit</li>
          </Breadcrumb>
        </div>
      </div>
      <div className="PageContent DataForm">
        <div className="ContentHeader">
          <div className="Title">
            <h1>Edit Warehouse</h1>
          </div>
        </div>
        <div className="MainContent">
          <div className="FormContainer">
            {data ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setFieldValue }) => {
                  return (
                    <Form>
                      <div className={["FormBody", styles.FormBody].join(" ")}>
                        <FormikControl
                          control="selectAsync"
                          label="Region *"
                          name="region_id"
                          value={data.region}
                          setFieldValue={setFieldValue}
                          loadOptions={loadRegions}
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Name *"
                          name="name"
                          maxLength="100"
                        />

                        <FormikControl
                          control="input"
                          type="text"
                          label="Address *"
                          name="address"
                          maxLength="255"
                        />
                      </div>

                      <div
                        className={["FormActions", styles.FormActions].join(
                          " "
                        )}
                      >
                        <Button
                          btnType="Primary"
                          type="Submit"
                          width="W100"
                          isLoading={isLoading}
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : null}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Update;
