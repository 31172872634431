import React from "react";
import { Helmet } from "react-helmet";

import styles from "./SignIn.module.css";

import SignInForm from "../../components/SignInForm/SignInForm";

const SignIn = () => {
  return (
    <div className={styles.SignIn}>
      <Helmet>
        <title>Sign In | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <SignInForm />
    </div>
  );
};

export default SignIn;
