import React, { useState, useCallback, useEffect } from "react";
import axios from "../../../axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./OverviewUpdate.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";

const OverviewUpdate = (props) => {
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(() => {
    axios
      .get(`/products/${props.productId}`)
      .then((response) => {
        const data = response.data;
        data.status = data.status.id.toString();
        props.setProductName(data.name);
        setData({ ...data });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialValues = {
    name: data.name,
    alternate_name: data.alternate_name,
    code: data.code,
    highlights: data.highlights,
    description: data.description,
    keywords: data.keywords,
    status: data.status,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    code: Yup.string().required("Code is required"),
    highlights: Yup.string().required("Highlights is required"),
    description: Yup.string().required("Description is required"),
    keywords: Yup.string().required("Keywords is required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    axios
      .put(`/products/${props.productId}`, values)
      .then((response) => {
        toast.success(response.data.message);
        props.setProductId(response.data.id);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const statusOptions = [
    { value: "0", label: "Disabled" },
    { value: "1", label: "Enabled" },
  ];

  return (
    <div className={styles.Overview}>
      <div className="FormContainer">
        {data ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => {
              return (
                <Form>
                  <div
                    className={["FormBody Type2", styles.FormBody].join(" ")}
                  >
                    <FormikControl
                      control="input"
                      type="text"
                      label="Name *"
                      name="name"
                      maxLength="255"
                    />

                    <FormikControl
                      control="input"
                      type="text"
                      label="Alternate Name"
                      name="alternate_name"
                      maxLength="255"
                    />

                    <FormikControl
                      control="input"
                      type="text"
                      label="Code *"
                      name="code"
                      maxLength="45"
                    />

                    <FormikControl
                      control="richTextInput"
                      label="Description *"
                      name="description"
                      setFieldValue={setFieldValue}
                      value={data.description}
                    />

                    <FormikControl
                      control="richTextInput"
                      label="Active Constituents / Principles *"
                      name="highlights"
                      setFieldValue={setFieldValue}
                      value={data.highlights}
                    />

                    <FormikControl
                      control="input"
                      type="text"
                      label="Keywords *"
                      name="keywords"
                    />

                    <FormikControl
                      control="select"
                      label="Status *"
                      name="status"
                      value={data.status}
                      options={statusOptions}
                      setFieldValue={setFieldValue}
                    />
                  </div>

                  <div
                    className={["FormActions", styles.FormActions].join(" ")}
                  >
                    <Button
                      btnType="Primary"
                      type="Submit"
                      width="W100"
                      isLoading={isLoading}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : null}
      </div>
      <ToastContainer />
    </div>
  );
};

export default OverviewUpdate;
