import React, { useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../../axios";
import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Photos.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import EditButton from "../../../components/EditButton/EditButton";
import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Modal from "../../../components/Modal/Modal";
import DialogBox from "../../../components/DialogBox/DialogBox";

import AddNewIcon from "../../../assets/images/add-new-icon.svg";

const Photos = (props) => {
  const [data, setData] = useState([]);

  const fetchData = useCallback(() => {
    axios
      .get(`/products/${props.productId}/photos`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [props]);

  const [showDeleteDialogBox, setShowDeleteDialogBox] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);

  const handleNewForm = () => {
    const formValues = {
      id: "",
      image: "",
    };

    setFormValues(formValues);
    setEditMode(false);
    setShowNewForm(!showNewForm);
  };

  const handleEdit = (item) => {
    setFormValues(item);
    setEditMode(true);
    setShowNewForm(true);
  };

  const initialValues = {
    image: "",
  };

  const validationSchema = Yup.object({
    image: Yup.string().required("Image is required"),
  });

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("image", values.image);

    if (!editMode) {
      axios
        .post(`/products/${props.productId}/photos`, formData)
        .then((response) => {
          toast.success(response.data.message);
          setShowNewForm(false);
          fetchData();
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        });
    } else {
      axios
        .put(`/products/${props.productId}/photos/${formValues.id}`, formData)
        .then((response) => {
          toast.success(response.data.message);
          setShowNewForm(false);
          fetchData();
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        });
    }
  };

  const newForm = showNewForm ? (
    <Modal
      show={showNewForm}
      modalClosed={handleNewForm}
      title={`${editMode ? "EDIT" : "ADD"}
        Photo`}
    >
      <div className="FormContainer ModalForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form>
                <div className={["FormBody", styles.FormBody].join(" ")}>
                  <FormikControl
                    control="fileInput"
                    label="Image *"
                    name="image"
                    accept=".jpg,.png"
                    setFieldValue={setFieldValue}
                    fileFormats="PNG, JPG"
                    maxFileSize="2MB"
                    value={formValues ? formValues.image : ""}
                  />
                </div>
                <div className="FormActions">
                  <Button
                    btnType="Primary"
                    type="Submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  ) : null;

  const handleDelete = (id = null) => {
    setShowDeleteDialogBox(!showDeleteDialogBox);
    setActiveItem(id);
  };

  const confirmDelete = () => {
    axios
      .delete(`/products/${props.productId}/photos/${activeItem}`)
      .then((response) => {
        toast.success(response.data.message);
        setShowDeleteDialogBox(false);
        fetchData();
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.Photos}>
      {data.length < 1 ? (
        <div className={styles.PageActions}>
          <Button btnType="Success" clicked={handleNewForm}>
            <img src={AddNewIcon} alt="New" /> <span>Add Photo</span>
          </Button>
        </div>
      ) : null}
      {data.length ? (
        <>
          <div className={styles.PhotosWrapper}>
            {data.map((item) => {
              return (
                <div className={styles.Photo} key={item.id}>
                  <div className={styles.Image}>
                    <a href={item.image} target="_blank">
                      <img src={item.image}/>
                    </a>
                  </div>
                  <div className={styles.Actions}>
                    <EditButton clicked={() => handleEdit(item)} />
                    <DeleteButton clicked={() => handleDelete(item.id)} />
                  </div>
                </div>
              );
            })}
          </div>
          {props.mode !== "edit" ? (
            <div className={["FormActions", styles.FormActions].join(" ")}>
              <Button
                btnType="Primary"
                clicked={() => props.handleActiveStep(7)}
              >
                Next
              </Button>
            </div>
          ) : null}
        </>
      ) : (
        <div>No Photos found</div>
      )}
      <DialogBox
        show={showDeleteDialogBox}
        confirm={confirmDelete}
        cancel={handleDelete}
        title="Delete Photo"
        message="Are you sure you want to delete this Photo?"
      />
      {newForm}
      <ToastContainer />
    </div>
  );
};

export default Photos;
