import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./ChangePassword.module.css";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import FormikControl from "../../components/FormikControl/FormikControl";
import Button from "../../components/Button/Button";

const Create = () => {
  const [formId, setFormId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const validationSchema = Yup.object({
    currentPassword: Yup.string()
      .required("This field is required")
      .trim()
      .max(14, "Password must contain only 14 characters")
      .min(7, "Password must be at least 7 characters"),
    newPassword: Yup.string()
      .required("This field is required")
      .trim()
      .max(14, "Password must contain only 14 characters")
      .min(7, "Password must be at least 7 characters"),
    confirmNewPassword: Yup.string()
      .required("This field is required")
      .trim()
      .max(14, "Password must contain only 14 characters")
      .min(7, "Password must be at least 7 characters")
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Both password need to be the same"
        ),
      }),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    const formData = {
      current_password: values.currentPassword,
      password: values.newPassword,
    };

    axios
      .post("admins/change-password", formData)
      .then((response) => {
        toast.success(response.data.message);
        setFormId(formId + 1);
        setPassword("");
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.Create}>
      <Helmet>
        <title>Change Password | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Change Password</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>Change Password</li>
          </Breadcrumb>
        </div>
      </div>
      <div className="PageContent DataForm">
        <div className="ContentHeader">
          <div className="Title">
            <h1>Change Password</h1>
          </div>
        </div>
        <div className="MainContent">
          <div className={["FormContainer", styles.FormContainer].join(" ")}>
            <Formik
              key={formId}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {() => {
                return (
                  <Form>
                    <div className={["FormBody", styles.FormBody].join(" ")}>
                      <FormikControl
                        control="password"
                        type="password"
                        label="Current Password"
                        name="currentPassword"
                        autoComplete="new-password"
                      />

                      <FormikControl
                        control="passwordStrength"
                        type="password"
                        label="New Password"
                        name="newPassword"
                        setPassword={setPassword}
                        password={password}
                      />

                      <FormikControl
                        control="password"
                        type="password"
                        label="Confirm New Password"
                        name="confirmNewPassword"
                      />
                    </div>
                    <div className="FormActions">
                      <Button
                        btnType="Primary"
                        type="Submit"
                        width="W100"
                        isLoading={isLoading}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default Create;
