import React from "react";
import { Route } from "react-router-dom";

import styles from "./MainLayout.module.css";

import Header from "../../../components/Header/Header";
import MainNav from "../../../components/Navigations/MainNav/MainNav";

const MainLayout = (props) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Container}>
        <MainNav />
        <div className={styles.PageContainer}>
          <Header />
          <Route component={props.component} {...props} />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;