import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { TbUsers } from "react-icons/tb";
import { RiLockPasswordLine } from "react-icons/ri";
import { IoIosBarcode } from "react-icons/io";
import { MdOutlineShoppingCart } from "react-icons/md";
import { RiAdminLine } from "react-icons/ri";
import { MdOutlineCategory } from "react-icons/md";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { RiMap2Line } from "react-icons/ri";
import { TbBuildingWarehouse } from "react-icons/tb";
import { IoImagesOutline } from "react-icons/io5";

import styles from "./MainNav.module.css";

import NavItem from "./NavItem/NavItem";

import Logo from "../../../assets/images/logo.svg";

const MainNav = () => {
  return (
    <div className={styles.MainNav}>
      <div className={styles.Logo}>
        <Link to="/">
          <img src={Logo} alt={process.env.REACT_APP_NAME} />
        </Link>
      </div>
      <ul className={styles.NavBar}>
        <div>
          <NavItem link="/" exact>
            <MdOutlineSpaceDashboard /> Dashboard
          </NavItem>

          <NavItem link="/products">
            <IoIosBarcode /> Products
          </NavItem>

          <NavItem link="/orders">
            <MdOutlineShoppingCart /> Orders
          </NavItem>

          <NavItem link="/product-enquires">
            <MdOutlineShoppingCart /> Product Enquires
          </NavItem>

          <NavItem link="/users">
            <TbUsers /> Users
          </NavItem>

          <NavItem link="/categories">
            <MdOutlineCategory /> Categories
          </NavItem>

          <NavItem link="/applications">
            <MdOutlineSettingsApplications /> Applications
          </NavItem>

          <NavItem link="/certificates">
            <MdOutlineCategory /> Certificates
          </NavItem>

          <NavItem link="/regions">
            <RiMap2Line /> Regions
          </NavItem>

          <NavItem link="/warehouses">
            <TbBuildingWarehouse /> Warehouses
          </NavItem>

          <NavItem link="/slides">
            <IoImagesOutline /> Slides
          </NavItem>

          <NavItem link="/admins">
            <RiAdminLine /> Admins
          </NavItem>

          <NavItem link="/change-password">
            <RiLockPasswordLine /> Change Password
          </NavItem>
        </div>
      </ul>
    </div>
  );
};

export default MainNav;
