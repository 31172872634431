import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiEdit2 } from "react-icons/fi";

import styles from "./View.module.css";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Button from "../../../components/Button/Button";
import DialogBox from "../../../components/DialogBox/DialogBox";

const View = () => {
  const [data, setData] = useState(null);
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [status, setStatus] = useState(null);

  const params = useParams();

  const fetchData = useCallback(() => {
    axios
      .get(`/product-enquires/${params.id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, [params]);

  const handleStatusUpdate = (status) => {
    setShowStatusDialog(!showStatusDialog);
    setStatus(status);
  };

  const updateStatus = () => {
    const values = {
      status: status,
    };
    axios
      .put(`/product-enquires/${params.id}`, values)
      .then((response) => {
        fetchData();
        toast.success(response.data.message);
        setShowStatusDialog(false);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.View}>
      <Helmet>
        <title>
          View Details | Product Enquires | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Product Enquires</h1>
          </div>
          <Breadcrumb>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/product-enquires">Product Enquires</Link>
            </li>
            <li>View</li>
          </Breadcrumb>
        </div>
      </div>
      {data ? (
        <div className={["PageContent", styles.UserOverview].join(" ")}>
          <div className={styles.DetailsContainer}>
            <div className="ContentHeader">
              <h2>Product Enquiry</h2>
              <div className="HeaderActions">
                {data.status.id === 1 ? (
                  <Button
                    btnType="Success"
                    clicked={() => handleStatusUpdate(2)}
                  >
                    <FiEdit2 />
                    <span>Change status to Processing</span>
                  </Button>
                ) : null}
                {data.status.id === 2 ? (
                  <Button
                    btnType="Success"
                    clicked={() => handleStatusUpdate(3)}
                  >
                    <FiEdit2 />
                    <span>Change status to Fulfilled</span>
                  </Button>
                ) : null}
              </div>
            </div>
            <div className={styles.DetailOverview}>
              <div className={styles.Detail}>
                <div>User</div>
                <div>
                  <Link to={`/users/${data.user.id}/view`}>
                    {data.user.name}
                  </Link>
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Description</div>
                <div>{data.description}</div>
              </div>
              <div className={styles.Detail}>
                <div>Quantity</div>
                <div>{data.qty}</div>
              </div>
              <div className={styles.Detail}>
                <div>Product</div>
                <div>{data.package_type.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Size</div>
                <div>{data.package_size} kg</div>
              </div>
              <div className={styles.Detail}>
                <div>Document</div>
                <div>
                  {data.document ? <a href={data.document} target="_blank">Open</a> : "NA"}
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Status</div>
                <div>{data.status.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Created at</div>
                <div>
                  {moment(data.created_at).format("DD MMM YYYY, h:mm A")}
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Updated at</div>
                <div>
                  {data.updated_at
                    ? moment(data.updated_at).format("DD MMM YYYY, h:mm A")
                    : "NA"}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <DialogBox
        show={showStatusDialog}
        confirm={updateStatus}
        cancel={handleStatusUpdate}
        title="Update Status"
        message="Are you sure you want to update the status?"
      />
      <ToastContainer />
    </div>
  );
};

export default View;
