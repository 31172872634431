import React from "react";

import styles from "./DeleteButton.module.css";

import DeleteIcon from "../../assets/images/delete-icon.png";
import DeleteIconDark from "../../assets/images/delete-icon-dark.png";

const DeleteButton = (props) => {
  return (
    <div className={styles.DeleteButton} onClick={props.clicked}>
      <div className={styles.Button}>
        <img
          src={
            props.theme && props.theme === "Dark" ? DeleteIconDark : DeleteIcon
          }
          alt="Edit"
        />
      </div>
    </div>
  );
};

export default DeleteButton;
