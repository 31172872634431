import React, { useState } from "react";

import styles from "./Create.module.css";

import Overview from "../Overview/Overview";
import Categories from "../Categories/Categories";
import Applications from "../Applications/Applications";
import Documents from "../Documents/Documents";
import Photos from "../Photos/Photos";
import Sizes from "../Sizes/Sizes";
import Prices from "../Prices/Prices";
import Inventory from "../Inventory/Inventory";
import Certificates from "../Certificates/Cetificates";

const Create = () => {
  const [productId, setProductId] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);

  const handleActiveStep = (step) => {
    setActiveStep(step);
    setCompletedSteps.push(step);
  };

  return (
    <div className={styles.Create}>
      <div className="PageContent DataForm">
        <div className="MainContent">
          <div className={["FormWizard", styles.FormWizard].join(" ")}>
            <ul>
              <li className={activeStep === 1 ? "Active" : null}>
                <span>1</span>
                <span>Overview</span>
              </li>
              <li className={activeStep === 2 ? "Active" : null}>
                <span>2</span>
                <span>Categories</span>
              </li>
              <li className={activeStep === 3 ? "Active" : null}>
                <span>3</span>
                <span>Applications</span>
              </li>
              <li className={activeStep === 4 ? "Active" : null}>
                <span>4</span>
                <span>Documents</span>
              </li>
              <li className={activeStep === 5 ? "Active" : null}>
                <span>5</span>
                <span>Certificates</span>
              </li>
              <li className={activeStep === 6 ? "Active" : null}>
                <span>6</span>
                <span>Photos</span>
              </li>
              <li className={activeStep === 7 ? "Active" : null}>
                <span>7</span>
                <span>Sizes</span>
              </li>
              <li className={activeStep === 8 ? "Active" : null}>
                <span>8</span>
                <span>Pricings</span>
              </li>
              <li className={activeStep === 9 ? "Active" : null}>
                <span>9</span>
                <span>Inventory</span>
              </li>
            </ul>
          </div>
          <div className="FormContent">
            {activeStep === 1 ? (
              <Overview
                setProductId={setProductId}
                handleActiveStep={handleActiveStep}
              />
            ) : null}

            {activeStep === 2 ? (
              <Categories
                productId={productId}
                handleActiveStep={handleActiveStep}
              />
            ) : null}

            {activeStep === 3 ? (
              <Applications
                productId={productId}
                handleActiveStep={handleActiveStep}
              />
            ) : null}

            {activeStep === 4 ? (
              <Documents
                productId={productId}
                handleActiveStep={handleActiveStep}
              />
            ) : null}

            {activeStep === 5 ? (
              <Certificates
                productId={productId}
                handleActiveStep={handleActiveStep}
              />
            ) : null}

            {activeStep === 6 ? (
              <Photos
                productId={productId}
                handleActiveStep={handleActiveStep}
              />
            ) : null}

            {activeStep === 7 ? (
              <Sizes
                productId={productId}
                handleActiveStep={handleActiveStep}
              />
            ) : null}

            {activeStep === 8 ? (
              <Prices
                productId={productId}
                handleActiveStep={handleActiveStep}
              />
            ) : null}

            {activeStep === 9 ? (
              <Inventory
                productId={productId}
                handleActiveStep={handleActiveStep}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
