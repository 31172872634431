import React, { useCallback, useState, useEffect } from "react";
import axios from "../../../axios";
import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Certificates.module.css";

import FormikControl from "../../../components/FormikControl/FormikControl";
import Button from "../../../components/Button/Button";
import EditButton from "../../../components/EditButton/EditButton";
import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Modal from "../../../components/Modal/Modal";
import DialogBox from "../../../components/DialogBox/DialogBox";

import AddNewIcon from "../../../assets/images/add-new-icon.svg";

const Certificates = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(() => {
    axios
      .get(`/products/${props.productId}/certificates`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [props]);

  const [showDeleteDialogBox, setShowDeleteDialogBox] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);

  const handleNewForm = () => {
    const formValues = {
      id: "",
      certificate_id: "",
    };

    setFormValues(formValues);
    setEditMode(false);
    setShowNewForm(!showNewForm);
  };

  const handleEdit = (id) => {
    axios
      .get(`/products/${props.productId}/certificates/${id}`)
      .then((response) => {
        const formValues = {
          id: response.data.id,
          certificate_id: response.data.certificate_id,
        };

        setFormValues(formValues);
        setEditMode(true);
        setShowNewForm(true);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const initialValues = {
    certificate_id: formValues ? formValues.certificate_id : "",
  };

  const validationSchema = Yup.object({
    certificate_id: Yup.string().required("Certificate is required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    const data = {
      certificate_id: values.certificate_id,
    };

    if (!editMode) {
      axios
        .post(`/products/${props.productId}/certificates`, data)
        .then((response) => {
          toast.success(response.data.message);
          fetchData();
          setShowNewForm(false);
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      axios
        .put(`/data/${props.productId}/certificates/${formValues.id}`, data)
        .then((response) => {
          toast.success(response.data.message);
          fetchData();
          setShowNewForm(false);
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // load Certificates
  const loadCertificates = (inputValue) => {
    return axios
      .get(`/certificates?page=1&per-page=200&query=${inputValue}`)
      .then((res) => res.data);
  };

  const newForm = showNewForm ? (
    <Modal
      show={showNewForm}
      modalClosed={handleNewForm}
      title={`${editMode ? "EDIT" : "ADD"}
        Certificate`}
    >
      <div className="FormContainer ModalForm">
        <Formik
          initialValues={initialValues}
          validationSchema={editMode ? null : validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <div className={["FormBody", styles.FormBody].join(" ")}>
                  <FormikControl
                    control="selectAsync"
                    label="Certificate *"
                    name="certificate_id"
                    value=""
                    setFieldValue={setFieldValue}
                    loadOptions={loadCertificates}
                  />
                </div>
                <div className="FormActions">
                  <Button
                    btnType="Primary"
                    type="Submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  ) : null;

  const handleDelete = (id = null) => {
    setShowDeleteDialogBox(!showDeleteDialogBox);
    setActiveItem(id);
  };

  const confirmDelete = () => {
    axios
      .delete(`/products/${props.productId}/certificates/${activeItem}`)
      .then((response) => {
        toast.success(response.data.message);
        setShowDeleteDialogBox(false);
        fetchData();
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.ProductDocuments}>
      <div className={styles.PageActions}>
        <Button btnType="Success" clicked={handleNewForm}>
          <img src={AddNewIcon} alt="New" /> <span>Add Certificate</span>
        </Button>
      </div>
      <div className={styles.DocumentsWrapper}>
        {data.length ? (
          <>
            <div className={styles.Documents}>
              <table className="type-1">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Uploaded at</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => {
                    return (
                      <tr>
                        <td>
                          <a href={item.certificate} target="_blank">
                            {item.name}
                          </a>
                        </td>
                        <td>
                          {moment(item.created_at).format(
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </td>
                        <td className="ActionsColumn">
                          <EditButton clicked={() => handleEdit(item.id)} />
                          <DeleteButton clicked={() => handleDelete(item.id)} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {props.mode !== "edit" ? (
              <div className={["FormActions", styles.FormActions].join(" ")}>
                <Button
                  btnType="Primary"
                  clicked={() => props.handleActiveStep(6)}
                >
                  Next
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <div>No Certificates found</div>
        )}
      </div>
      <DialogBox
        show={showDeleteDialogBox}
        confirm={confirmDelete}
        cancel={handleDelete}
        title="Delete Certificate"
        message="Are you sure you want to delete this Certificate?"
      />
      {newForm}
      <ToastContainer />
    </div>
  );
};

export default Certificates;
