import jwt_decode from "jwt-decode";
import SignInTypes from "./SignIn.Types";
import axios from "../../axios";

export const signInStart = () => ({
  type: SignInTypes.SIGN_IN_START,
});

export const signInSuccess = (token) => ({
  type: SignInTypes.SIGN_IN_SUCCESS,
  token: token,
});

export const signInFailure = (error) => ({
  type: SignInTypes.SIGN_IN_FAILURE,
  error: error,
});

export const signIn = (email, password) => {
  return (dispatch) => {
    dispatch(signInStart());
    const signInCreds = {
      email: email,
      password: password,
    };
    axios
      .post("/admins/login", signInCreds)
      .then((response) => {
        const jwtToken = jwt_decode(response.data.access_token);
        const expirationDate = new Date(jwtToken.exp * 1000);

        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("name", response.data.name);

        const expiresIn = jwtToken.exp - Math.floor(Date.now() / 1000);
        dispatch(checkAuthTimeout(expiresIn));

        dispatch(signInSuccess(response.data.access_token, response.data.id));
      })
      .catch((error) => {
        dispatch(signInFailure(error.response.data));
      });
  };
};

export const refreshToken = () => {
  return (dispatch) => {
    axios
      .post("/admins/refresh-token")
      .then((response) => {
        const jwtToken = jwt_decode(response.data.access_token);
        const expirationDate = new Date(jwtToken.exp * 1000);

        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("name", response.data.name);

        const expiresIn = jwtToken.exp - Math.floor(Date.now() / 1000);
        dispatch(checkAuthTimeout(expiresIn));

        dispatch(signInSuccess(response.data.access_token, response.data.id));
      })
      .catch(() => {
        dispatch(logoutSession());
      });
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(refreshToken());
    }, (expirationTime - 60) * 1000);
  };
};

export const logout = () => {
  return (dispatch) => {
    axios.post("/admins/logout").then(() => {
      dispatch(logoutSession());
    });
  };
};

export const logoutSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("name");

  return {
    type: SignInTypes.LOGOUT_SESSION,
  };
};

export const authCheck = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logoutSession());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));

      if (expirationDate < new Date()) {
        dispatch(logoutSession());
      } else {
        dispatch(signInSuccess(token));
        dispatch(
          checkAuthTimeout(
            expirationDate.getSeconds() - new Date().getSeconds()
          )
        );
      }
    }
  };
};
