import React, { Component } from "react";
import axios from "../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Applications.module.css";

import Button from "../../../components/Button/Button";
import Checkbox from "../../../components/FormElements/Checkbox/Checkbox";

class Index extends Component {
  state = {
    isLoading: false,
    applications: [],
    assignedApplications: [],
    finalApplications: [],
  };

  componentDidMount() {
    this.loadApplications();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.applications !== this.state.applications) {
      this.loadAssignedApplications();
    }

    if (prevState.assignedApplications !== this.state.assignedApplications) {
      this.handleAssignedApplications();
    }
  }

  loadApplications = () => {
    axios
      .get("/applications?page=1&per-page=9999")
      .then((response) => {
        const applications = response.data.map((application) => {
          return {
            id: application.id,
            assignmentId: null,
            name: application.name,
            checked: false,
          };
        });

        this.setState({ applications: applications });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  loadAssignedApplications = () => {
    axios
      .get(
        `/products/${this.props.productId}/applications?page=1&per-page=9999&product_id=${this.props.productId}`
      )
      .then((response) => {
        this.setState({ assignedApplications: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAssignedApplications = () => {
    const updatedApplications = this.state.applications;
    this.state.applications.filter((application, i) => {
      this.state.assignedApplications.filter((assignedApplication) => {
        if (assignedApplication.application_id === application.id) {
          updatedApplications[i].checked = true;
          updatedApplications[i].assignmentId = assignedApplication.id;
        }
      });
    });

    this.setState({ finalApplications: updatedApplications });
  };

  handleApplicationChange = (val) => {
    this.state.finalApplications.filter((application, i) => {
      if (val === application.id) {
        const finalApplication = this.state.finalApplications[i];
        if (finalApplication.checked) {
          this.removeCategory(finalApplication.assignmentId, i);
        } else {
          this.assignCategory(finalApplication.id, i);
        }
      }
    });
  };

  assignCategory = (id, i) => {
    const formData = {
      application_id: id,
      product_id: this.props.productId,
    };

    axios
      .post(`/products/${this.props.productId}/applications`, formData)
      .then((response) => {
        toast.success(response.data.message);
        console.log("response", response.data);

        const updatedFinalApplications = this.state.finalApplications;
        updatedFinalApplications[i].assignmentId = response.data.id;
        updatedFinalApplications[i].checked = true;
        this.setState({
          finalApplications: updatedFinalApplications,
        });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  removeCategory = (id, i) => {
    axios
      .delete(`/products/${this.props.productId}/applications/${id}`)
      .then((response) => {
        toast.success(response.data.message);

        const updatedFinalApplications = this.state.finalApplications;
        updatedFinalApplications[i].assignmentId = null;
        updatedFinalApplications[i].checked = false;
        this.setState({
          finalApplications: updatedFinalApplications,
        });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  render() {
    let applications = "";
    if (this.state.finalApplications) {
      applications = this.state.finalApplications.map((application) => {
        return (
          <div className={styles.Application} key={application.id}>
            <Checkbox
              name={application.id}
              label={application.name}
              checked={application.checked}
              change={this.handleApplicationChange}
            />
          </div>
        );
      });
    }

    return (
      <div className={styles.Create}>
        <div className={styles.Applications}>{applications}</div>
        {this.state.finalApplications.some((item) => item.checked === true) &&
        this.props.mode !== "edit" ? (
          <div className={["FormActions", styles.FormActions].join(" ")}>
            <Button
              btnType="Primary"
              clicked={() => this.props.handleActiveStep(4)}
            >
              Next
            </Button>
          </div>
        ) : null}
        <ToastContainer />
      </div>
    );
  }
}

export default Index;
